export default toastType => {
  let message;
  if (toastType === 'success' || toastType === 'warning') {
    message =
      'operation(s) mapped. You can adjust crop zones via the associated task.';
  } else {
    message =
      'operation(s) were unable to be matched, contact support if issue persists.';
  }
  return message;
};

export const toastTitle = toastType => {
  let message;
  if (toastType === 'success' || toastType === 'warning') {
    message = 'Data matched to';
  } else {
    message = 'Data unable to be matched to';
  }
  return message;
};
