import { useCallback, useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { createServerError, parseServerError } from 'helpers/errorHelpers';
import { recommendations as recommendationsApi } from 'utilities/api';
import { resetOrg } from 'utilities/recommendationsFakeAPI';
import { useHistory } from 'react-router-dom';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { useToast } from '../components/ToastContainer';
import { paths } from '../routes/paths';

const useRecommendations = () => {
  const [, dispatch] = useContext(Context);
  const amplitude = useContext(AmplitudeContext);

  const [isLoading, setIsLoading] = useState(false);

  const [recommendations, setRecommendations] = useState([]);

  const [recommendationsCount, setRecommendationsCount] = useState(0);
  const [recPageNumber, setRecPageNumber] = useState();

  const [request, setRequest] = useState({
    page: recPageNumber || 0,
    size: PAGINATION_SIZE,
    dateRange: [],
    titleQuery: '',
    sortBy: '',
    sortDir: '',
    cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
  });

  const toast = useToast();
  const history = useHistory();

  const getRecommendations = useCallback(
    async (newData, pageNo, reportCropSeasonIds) => {
      setIsLoading(true);
      if (newData) {
        setRecommendations(newData);
      } else {
        const { promise } = recommendationsApi.fetch(
          null,
          {
            size: request.size,
            pageNo,
            seasonIdsCommaSeparated:
              reportCropSeasonIds ||
              JSON.parse(localStorage.getItem('selectedCropSeasons')).join(','),
            includeExtended: true,
            ...(request.titleQuery ? { searchText: request.titleQuery } : {}),
            ...(request.dateRange?.length
              ? {
                  since: format(request.dateRange[0], 'yyyy-MM-dd'),
                  until: format(request.dateRange[1], 'yyyy-MM-dd')
                }
              : {})
          },
          {},
          null,
          false
        );

        await promise
          .then(({ data }) => {
            setRecommendations(data.results);
            setRecommendationsCount(data.totalElements);
          })
          .catch(catchCancel)
          .catch(err => {
            parseServerError(dispatch)(err);
          })
          .finally(() =>
            setTimeout(() => {
              setIsLoading(false);
            }, 700)
          );
      }
      setIsLoading(false);
    },
    [dispatch, request]
  );

  const reset = () => {
    setRequest({
      ...request,
      page: recPageNumber || 0,
      totalPages: 0,
      size: PAGINATION_SIZE,
      cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
    });
  };

  const reload = useCallback(
    async (newData = null) => {
      getRecommendations(newData);
      setRecPageNumber();
    },
    [getRecommendations]
  );

  useEffect(() => {
    return () => {
      // destroy fake api records
      resetOrg();
    };
  }, []);

  useEffect(() => {
    reload();
  }, [reload, request]);

  const goToPage = useCallback(
    newPage => {
      getRecommendations(null, newPage);
      setRequest(r => ({
        ...r,
        page: newPage
      }));
      setRecPageNumber(newPage);
    },
    [setRequest, getRecommendations]
  );

  const filterByDateRange = useCallback(
    newDateRange => {
      if (newDateRange.length === 2 || newDateRange.length === 0) {
        setRequest(r => ({ ...r, page: 0, dateRange: newDateRange }));
      }
    },
    [setRequest]
  );

  const filterByTitleQuery = useCallback(
    newQuery => {
      setRequest(r => ({ ...r, page: 0, titleQuery: newQuery }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, page: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  const convertToTask = async recommendation => {
    setIsLoading(true);
    const { promise } = recommendationsApi
      .createChildApi({
        action: `recommendation/${recommendation.id}/task`
      })
      .create(null, {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: recommendation.orgId
          })
        }
      });
    await promise
      .then(({ data: taskId }) => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.Tasks.saveTaskFromRecSuccess
        );
        toast.success(`Task created`, {
          content: `Recommendation ${recommendation.title} successfully converted into a Task`,
          actionButton: () => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.Tasks.viewTask
            );
            history.push(`${paths.tasks}/${taskId}/view`);
          }
        });
      })
      .catch(catchCancel)
      .catch(err => {
        const serverError = createServerError({}, err);
        toast.error('Error while creating task', {
          content: serverError.displayMessage,
          supportButton: true,
          timeout: 30000
        });
      })
      .finally(() =>
        setTimeout(() => {
          setIsLoading(false);
        }, 700)
      );
  };

  return {
    isLoading,
    recommendations,
    recommendationsCount,
    page: request.page,
    goToPage,
    dateRange: request.dateRange,
    filterByDateRange,
    titleQuery: request.titleQuery,
    filterByTitleQuery,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir,
    reload,
    reset,
    getRecommendations,
    convertToTask
  };
};

export default useRecommendations;
