const events = {
  epic: {
    Authentication: { signIn: 'sign in' },
    Organization: {
      id: 'organizations',
      accessOrg: 'access organization',
      switchingOrgs: 'switching orgs',
      orgLoadingCount: 'orgs loading count',
      paginatedProperties: 'paginated properties'
    },
    Properties: {
      id: 'properties',
      accessProps: 'access properties',
      editFieldName: 'edit field name',
      addCropZone: 'add crop zone',
      createField: 'create field',
      createProperty: 'create property',
      archiveFarm: 'archive farm',
      unarchiveFarm: 'unarchive farm',
      archiveField: 'archive field',
      unarchiveField: 'unarchive field',
      filters: {
        toggleArchivedProperties: 'show archived properties'
      },
      viewCostAnalysis: 'view cost analysis',
      accessPropertyMaps: 'access property maps',
      selectExportOption: 'select export option',
      createAnotherPropertyMap: 'create another property map'
    },
    ProductList: {
      editCustomProductCart: 'edit custom product cart',
      resetToListPrice: 'reset to list price',
      saveReiPhi: 'save rei phi',
      viewInvoice: 'view invoice',
      viewProductDetails: 'view product details',
      accessPPL: 'access product list'
    },
    PropertiesV2: {
      id: 'propertiesV2',
      accessPropertiesV2: 'access propertiesV2'
    },
    CropSeason: {
      id: 'crop seasons',
      accessCropSeasons: 'access crop seasons',
      accessCropSeasonsSuccess: 'access crop seasons success',
      viewCropSeason: 'view crop season',
      viewCropSeasonSuccess: 'view crop season success',
      createCropSeason: 'create crop season',
      createCropSeasonSuccess: 'create crop season success',
      saveCropSeason: 'save crop season',
      saveCropSeasonSuccess: 'save crop season success',
      cancelCropSeason: 'cancel crop season',
      cancelCropSeasonSuccess: 'cancel crop season success',
      editCropSeason: 'edit crop season',
      editCropSeasonSuccess: 'edit crop season success',
      updateCropSeason: 'update crop season',
      updateCropSeasonSuccess: 'update crop season success',
      deleteCropSeason: 'delete crop season',
      deleteCropSeasonSuccess: 'delete crop season success',
      pages: {
        cropSeasonPage: 'crop season page',
        cropZoneCreationPage: 'crop zone creation page',
        breadcrumbDropdown: 'breadcrumbs dropdown'
      }
    },
    Plans: {
      id: 'plans',
      accessPlan: 'access plans',
      deletePlan: 'delete plan',
      createPlan: 'create plan'
    },
    Recommendations: {
      id: 'recommendations',
      accessRecommendations: 'access recommendations',
      createRecommendations: 'create recommendation',
      deleteRecommendations: 'delete recommendation'
    },
    Tasks: {
      id: 'tasks',
      accessTasks: 'access tasks',
      accessTasksSuccess: 'access tasks success',
      cancelTask: 'cancel task',
      cancelTaskSuccess: 'cancel task success',
      viewTask: 'view task',
      viewTaskSuccess: 'view task success',
      selectDetailsTab: 'select details tab',
      selectDetailsTabSuccess: 'select details tab success',
      createTask: 'create task',
      createTaskSuccess: 'create task success',
      selectPropertiesTab: 'select properties tab',
      selectPropertiesTabSuccess: 'select properties tab success',
      selectProductTab: 'select product tab',
      selectProductTabSuccess: 'select product tab success',
      addProduct: 'add product',
      addProductSuccess: 'add product success',
      saveProduct: 'save product',
      saveProductSuccess: 'save product success',
      cancelProduct: 'cancel product',
      cancelProductSuccess: 'cancel product success',
      selectWeatherTab: 'selected weather tab',
      selectWeatherTabSuccess: 'selected weather tab success',
      saveTask: 'save task',
      saveTaskSuccess: 'save task success',
      duplicateTask: 'duplicate task',
      duplicateTaskSuccess: 'duplicate task success',
      editTask: 'edit task',
      editTaskSuccess: 'edit task success',
      updateTask: 'update task',
      updateTaskSuccess: 'update task success',
      saveTaskFromRec: 'save task from rec',
      saveTaskFromRecSuccess: 'save task from rec success',
      deleteTask: 'delete task',
      deleteTaskSuccess: 'delete task success',
      pages: {
        fromTaskLanding: 'top right button',
        fromPlan: 'converted from plan',
        fromRec: 'converted from rec',
        fromInvoice: 'converted from invoice',
        fromEmptyState: 'converted from empty state'
      }
    },
    Invoices: { id: 'invoices', accessInvoice: 'access invoices' },
    People: {
      id: 'people',
      accessPeople: 'access people',
      deletePerson: 'delete person',
      editPerson: 'edit person',
      createPerson: 'create person'
    },
    Companies: {
      id: 'companies',
      accessCompanies: 'access companies',
      deleteCompany: 'delete company',
      createCompany: 'create company',
      editCompany: 'edit company'
    },
    Equipment: {
      id: 'equipment',
      accessEquipment: 'access equipment',
      createEquipment: 'create equipment',
      deleteEquipment: 'delete equipment'
    },
    Reports: {
      id: 'reports',
      accessReports: 'access report',
      reportSuccess: 'report success',
      reportFailed: 'report failed'
    },
    YieldV2: {
      saleLocations: {
        createSaleLocation: 'create sale location',
        editSaleLocation: 'edit sale location',
        deleteSaleLocation: 'delete sale location'
      },
      storageLocations: {
        goToLoads: 'select go to loads button',
        delete: 'delete storage location',
        editStorageLocation: 'edit storage location',
        createStorageLocation: 'create storage location',
        saveStorageLocation: 'save storage location',
        createCompany: 'create company'
      },
      addYield: {
        createYield: 'create yield',
        selectTemplate: 'select template',
        saveLoads: 'save loads',
        createNewSourceStorage: 'create new storage',
        saveProperties: 'save properties',
        selectUOM: 'select UOM'
      },
      loads: {
        selectCrop: 'select crop',
        deleteYield: 'delete yield load',
        editYield: 'edit yield'
      }
    },
    Integrations: {
      JohnDeere: {
        operationsMatched: 'operations matched',
        operationsUnmatched: 'operations unmatched',
        numberOfOperationAvailable: 'number of operations available',
        cropZoneMatchFailure: 'crop zone match failure',
        cropZoneMatchSuccess: 'crop zone match success',
        cropZoneMatchPartialSuccess: 'crop zone match partial success',
        selectCropZone: 'select crop zone'
      },
      importRecommendationSelected: 'import recommendation selected',
      importRecommendationSearched: 'import recommendation searched',
      importRecommendationPropertiesMatch:
        'import recommendation properties match',
      importRecommendationTriggered: 'import recommendation triggered',
      importRecommendationProductsMatch: 'import recommendation products match',
      submissionStarted: 'submission started',
      submissionTaskClick: 'submission task click',
      submissionTaskRedirect: 'submission task redirect',
      submissionCredentialsFilled: 'submission credentials filled',
      submissionOrganizationFilled: 'submission organization filled',
      submissionSeasonSelected: 'submission season selected',
      submissionPropertiesSelected: 'submission properties selected',
      submissionProductsSelected: 'submission products selected',
      submissionProductsMatched: 'submission products matched',
      submissionFieldsFilledRegulatoryData:
        'submission fields filled regulatory data',
      submissionApplicatorDetailsFilled: 'submission applicator details filled',
      submissionRegulatoryDataReview: 'submission regulatory data review',
      submissionPropertiesMatched: 'submission properties matched',
      submissionCropZoneRemoved: 'submission crop zone removed',
      submissionRunning: 'submission running',
      submissionCompleted: 'submission completed',
      submissionCanceled: 'submission canceled',
      submissionEditClick: 'submission edit click',
      submissionRetryClick: 'submission retry click',
      IncomingInvoice: {
        invoiceList: 'incoming invoice list',
        invoiceSelect: 'incoming invoice select',
        invoiceMatch: 'incoming invoice match',
        invoiceReview: 'incoming invoice review',
        invoiceImport: 'incoming invoice import'
      }
    }
  }
};

export default events;
