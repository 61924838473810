import _ from 'lodash';

export const getProductListFilter = data => {
  if (!data) return {};

  const flatProductType = _.chain(
    _.flatMap(data, product => ({
      ...product,
      productType: product.productType.toUpperCase()
    }))
  )
    .groupBy('productType')
    .map((value1, key) => {
      return {
        productType: key,
        productTypeId: _.uniqueId('Type_'),
        children: value1
      };
    })
    .value();
  return flatProductType;
};

export const getProductCount = data => {
  const count = data.reduce((acc, cur) => {
    if (cur.children.length > 0) {
      return acc + cur.children.length;
    }
    return acc;
  }, 0);

  return count;
};

export const obtainAllKeys = node => {
  const result = [];
  node.forEach(eachNode => {
    let childKeys = [];
    if (eachNode.children) {
      childKeys = obtainAllKeys(eachNode.children);
    }
    result.push(...[eachNode.value ?? eachNode.key, ...childKeys]);
  });
  return result;
};

export const getFarmIds = data => {
  const flatProductType = _.chain(_.flatMap(data?.properties))
    .groupBy('type')
    .map((value1, key) => {
      return {
        farm: key,
        id: _.uniqueId('Type_'),
        children: value1
      };
    })
    .value();
  return flatProductType;
};

export const filterNodesBySearchFunction = searchText => {
  const lowerCaseSearchText = searchText.toLocaleLowerCase();

  const filterNodes = (filtered, node) => {
    const lowerCaseName = node.title.toLocaleLowerCase();
    // If the node matches, add the node and all its children
    if (lowerCaseName.indexOf(lowerCaseSearchText) > -1) {
      filtered.push({ ...node });
      // Else, look for matches in the node's children
    } else if (node.children) {
      const childMatches = node.children.reduce(filterNodes, []);
      if (childMatches.length) {
        filtered.push({ ...node, children: childMatches });
      }
    }

    return filtered;
  };
  return filterNodes;
};
