import { MAP_STYLE_TYPES } from 'helpers/constants';
import { MAP_PDF_SCALE } from './mapApiHelpers';

const styleInjector = (mapReference, urlStyle) => {
  const adjustTextSizeForRoads = map => {
    map.getStyle().layers.forEach(layer => {
      if (
        layer.type === 'symbol' &&
        layer.layout?.['text-field'] &&
        (layer.id.includes('road') ||
          layer.id.includes('street') ||
          layer.id.includes('highway'))
      ) {
        map.setLayoutProperty(layer.id, 'text-size', 8 * MAP_PDF_SCALE);
      }
    });
  };

  const isSatelliteStyle = urlStyle.includes(MAP_STYLE_TYPES.satellite);
  const isStreetsStyle = urlStyle.includes(MAP_STYLE_TYPES.street);

  if (isSatelliteStyle) {
    const geoJsonLayerId = mapReference
      .getStyle()
      .layers.find(
        layer =>
          layer.type === 'fill' ||
          layer.type === 'line' ||
          layer.type === 'symbol'
      )?.id;

    mapReference.addLayer(
      {
        id: 'backGround',
        type: 'background',
        paint: {
          'background-color': '#fff',
          'background-opacity': 0.2
        }
      },
      geoJsonLayerId
    );
    adjustTextSizeForRoads(mapReference);
  } else if (isStreetsStyle) {
    adjustTextSizeForRoads(mapReference);
  }
};

export default styleInjector;
