import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { Context } from 'components/Store';
import CropSeasonDateRange from 'components/CropSeasonDateRange';
import CropSeasonDropdown from 'components/CropSeasonDropdown';
import useCropSeasons from 'hooks/useCropSeasons';
import { isFormIncomplete } from 'screens/Reports/helpers/formTouched';
import { ReactComponent as AlertIcon } from 'assets/alertIcon.svg';
import Products from '../Products';
import UpdateReportButton from '../UpdateReportButton';
import Properties from '../Properties';
import Plans from '../Plans';
import MultiPrintAlert from '../MultiPrintAlert';

const ReportDetailsForm = ({
  activeReport,
  formConfig,
  formTouched,
  embedToken,
  exportOnly
}) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const { cropSeasons, loaded } = useCropSeasons();
  const orgId = Context?._currentValue[0]?.organization?.id;

  const submitDisabled =
    !formTouched(values) || isFormIncomplete(formConfig, values);

  const handleCropSeasonSelect = seasonSelected => {
    setFieldValue(
      'selectedCropSeasons',
      seasonSelected.filter(season => !!season)
    );
  };

  useEffect(() => {
    if (embedToken) {
      setFieldValue('initialSubmit', false);
    }
  }, [orgId, setFieldValue, embedToken]);

  useEffect(() => {
    if (values.initialSubmit || isFormIncomplete(formConfig, values)) return;

    submitForm(values);
    setFieldValue('initialSubmit', true);
  }, [formConfig, values, setFieldValue, submitForm, embedToken]);

  return (
    <div className="z-10 w-101 pl-16px">
      <div
        className="w-full pt-6 bg-white relative"
        style={{ height: '87vh', paddingBottom: '2vh' }}
      >
        <div className="px-8 overflow-y-auto w-full h-full">
          <h2 className="mb-4 text-xl font-semibold text-neutral-1000">
            Report Details
          </h2>
          {formConfig?.showCropSeasons && (
            <div className="mb-6">
              <CropSeasonDropdown
                allCropSeasonsData={cropSeasons}
                csLoaded={loaded}
                onSelect={handleCropSeasonSelect}
                selectedActive
                isRequired={false}
              />
              {formConfig?.showDateRange && (
                <CropSeasonDateRange
                  isRequired={false}
                  className="flex flex-row pb-6 mt-6 gap-5 justify-between relative"
                />
              )}
            </div>
          )}
          {formConfig?.showPlans && (
            <div className="mb-12">
              <Plans />
            </div>
          )}
          {formConfig?.showExcelAlert && (
            <div className="border-banner-alert-blue-30 border rounded bg-banner-alert-blue-10 mb-32px">
              <div className="py-12px px-16px">
                <div className="flex content-start gap-5 pb-4px">
                  <AlertIcon />
                  <h4 className="text-md whitespace-no-wrap">
                    Excel Report Includes:
                  </h4>
                </div>
                <ul className="text-neutral-60 text-sm ml-36px">
                  <li>• Property Information</li>
                  <li>• Plans</li>
                  <li>• Recommendations</li>
                  <li>• Tasks</li>
                  <li>• Invoices</li>
                </ul>
              </div>
            </div>
          )}
          {formConfig?.showPrintAlert && (
            <MultiPrintAlert values={values} activeReport={activeReport} />
          )}
          {formConfig?.showProperties && (
            <div className="mb-12">
              <Properties cropSeasons={cropSeasons} />
            </div>
          )}
          {formConfig?.showProducts && (
            <div className="mb-12">
              <Products cropSeasons={cropSeasons} />
            </div>
          )}
          {!exportOnly && <UpdateReportButton disabled={submitDisabled} />}
        </div>
      </div>
    </div>
  );
};

ReportDetailsForm.propTypes = {
  activeReport: PropTypes.shape().isRequired,
  formConfig: PropTypes.shape().isRequired,
  formTouched: PropTypes.func.isRequired,
  embedToken: PropTypes.string.isRequired,
  exportOnly: PropTypes.bool.isRequired
};

export default ReportDetailsForm;
