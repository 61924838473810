import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from '@agconnections/grow-ui';

import {
  filterNodesBySearchFunction,
  obtainAllKeys
} from 'screens/Reports/helpers/helperFunctions';
import SearchIcon from 'components/Icons/SearchIcon';

const GroupByProperties = ({ mappedData, loading, checked, setChecked }) => {
  const [expanded, setExpanded] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [propertyKeys, setPropertyKeys] = useState([]);

  const propertyNodes = useMemo(() => {
    if (loading || !mappedData?.properties?.length) return [];

    return mappedData.properties
      .map(farm => ({
        ...farm,
        fields: farm.fields?.filter(field => field.cropzones?.length > 0)
      }))
      .filter(farm => farm.fields?.length > 0)
      .map(farm => ({
        key: farm.id,
        title: farm.name,
        children: farm.fields.map(field => ({
          key: field.id,
          title: field.name,
          children: field.cropzones.map(cropzone => ({
            key: cropzone.id,
            title: cropzone.name
          }))
        }))
      }));
  }, [mappedData, loading]);

  const handleSelectAll = () => {
    if (checked.length === propertyKeys.length) {
      setChecked([]);
    } else {
      setChecked(propertyKeys);
    }
  };

  const onFilterChange = e => {
    const searchText = e.target.value;
    if (searchText?.length < 3) {
      setFilteredProperties(propertyNodes);
      return;
    }

    const filterNodes = filterNodesBySearchFunction(searchText);
    setFilteredProperties(propertyNodes.reduce(filterNodes, []));
  };

  useEffect(() => {
    if (propertyNodes.length) {
      setFilteredProperties(propertyNodes);
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyNodes]);

  useEffect(() => {
    const newPropertyKeys = obtainAllKeys(filteredProperties);
    setPropertyKeys(newPropertyKeys);
    if (initialLoad) {
      setChecked(newPropertyKeys);
      setInitialLoad(false);
      return;
    }
    setChecked(newPropertyKeys.filter(id => checked.includes(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProperties]);

  const isAllSelected = checked.length === propertyKeys.length;
  const isIndeterminate =
    checked.length > 0 && checked.length < propertyKeys.length;

  return (
    <div className="filter-container">
      {!loading ? (
        <>
          {mappedData.properties?.length > 0 && (
            <>
              <div
                className="mt-4 mb-2"
                data-testid="properties-filter-container"
              >
                <Input
                  data-testid="properties-search"
                  prefix={<SearchIcon />}
                  placeholder="Search..."
                  type="search"
                  onChange={onFilterChange}
                />
              </div>
              <div className="mt-2 mb-1 flex">
                <Checkbox
                  data-testid="properties-select-all"
                  size="small"
                  onChange={handleSelectAll}
                  checked={isAllSelected}
                  indeterminate={isIndeterminate}
                >
                  Select All
                </Checkbox>
              </div>

              <Tree
                checkable
                treeData={filteredProperties}
                checkedKeys={checked}
                onCheck={setChecked}
                expandedKeys={expanded}
                onExpand={setExpanded}
                selectable={false}
                showIcon={false}
              />
            </>
          )}
        </>
      ) : (
        <div className="mt-40px">
          <Spinner size={72} />
        </div>
      )}
    </div>
  );
};

GroupByProperties.defaultProps = {
  mappedData: [],
  loading: false
};
GroupByProperties.propTypes = {
  mappedData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired
};

export default GroupByProperties;
