/* eslint-disable no-else-return */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Context } from 'components/Store';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Button, Spinner, Toast } from '@agconnections/grow-ui';
import { getTime, format } from 'date-fns';
import { Formik } from 'formik';
import {
  task as taskApi,
  organization as organizationApi
} from 'utilities/api';
import { getInitials } from 'helpers/textHelpers';
import { useAuth } from 'utilities/base-auth';
import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import usePopulateMapData from 'hooks/usePopulateMapData';
import usePeopleData from 'hooks/usePeopleData';
import useLandingPageData from 'hooks/useLandingPageData';
import useInventoryApi from 'hooks/useInventoryApi';
import FormattedNumber from 'components/FormattedNumber';
import useStorage from 'hooks/useStorage';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import FormNavGuard from 'components/FormNavGuard';
import useMasterProducts from 'hooks/useMasterProducts';
import {
  APPLICATION_OPTIONS,
  DEFAULT_TASK_VALUES,
  FETCH_TYPES
} from 'helpers/constants';
import { getValidDate } from 'utilities/validation';
import { paths } from 'routes/paths';
import createAction from 'helpers/createAction';
import { SET_TASK_CREATION_FROM } from 'reducers/reducer';
import useWeatherData from '../helpers/useWeatherData';
import schema from './schema';
import { Details, Products, Properties, WeatherTab } from './Tabs';
import {
  hydrateProperties,
  getSelectedCropZones
} from './Tabs/Properties/helpers';
import { mapProductsToSave } from '../helpers/dataHelpers';
import roundDecimal from '../helpers/roundDecimal';
import useQuery from '../../../helpers/useQuery';
import useIntegrationsSubmission from '../helpers/useIntegrationsSubmission';

const Task = () => {
  const [context, dispatch] = useContext(Context);
  const { weatherAutomaticToggle, taskCreationFrom } = context;
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('Details');
  const { id } = useParams();
  const isCreateScreen =
    id === 'create' || id === 'convert' || id === 'duplicate';
  const history = useHistory();
  const nameInitials = getInitials(user.name);
  const yymmddDateString = format(new Date(), 'yyMMdd');
  const currentMilitaryTime = format(new Date(), 'kkmm');
  const [selectedMember, setSelectedMember] = useState(null);
  const [financialAccess, setFinancialAccess] = useState();
  const { people, loading: peopleLoading } = usePeopleData();
  const { loaded: hasLoaded, items: companies } = useLandingPageData(
    'worker/companies',
    ['company.name', 'company.legalname'],
    'company.name'
  );
  const tabs = {
    details: 'Details',
    properties: 'Properties',
    products: 'Products',
    weather: 'Weather'
  };
  const tasksTypes = {
    create: 'create',
    duplicate: 'duplicate',
    convert: 'convert'
  };
  const inventoryAPI = useInventoryApi('inventory/products-prices');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [initialValues, setInitialValues] = useState({
    ...DEFAULT_TASK_VALUES
  });
  const [amplitudeTabSelection, setAmplitudeTabSelection] = useState('');
  const [loading, setLoading] = useState(true);
  const amplitude = useContext(AmplitudeContext);
  const { getStdUnits } = useMasterProducts();
  const {
    fetchClearAppWeatherDataReturn,
    fetchURLDetails,
    setWeatherObj,
    getCoordinates,
    getNumericValue,
    DefaultWeatherData
  } = useWeatherData();

  // pulling localStorage out into a hook to help with testing
  const { storedValue } = useStorage('selectedCropSeasons');
  const [selectedCropSeasonIds, setSelectedCropSeasonIds] = useState(
    storedValue
  );
  const [loadInitialValues, setLoadInitialValues] = useState(true);
  const {
    isComingFromIntegrations,
    redirectToSubmission
  } = useIntegrationsSubmission();

  const getSelectedCropSeasonIds = () => {
    setSelectedCropSeasonIds(storedValue);
  };

  const {
    dataSorted: propertyLandingPageData,
    loading: propertiesLoading,
    fieldsAndAreasGeoJSONCollection,
    fieldsAndAreasCentroidCoordinates,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap,
    isCacheOn: true
  });

  const tabEventsMap = {
    [tabs.details]: amplitude.events.epic.Tasks.selectDetailsTabSuccess,
    [tabs.properties]: amplitude.events.epic.Tasks.selectPropertiesTabSuccess,
    [tabs.products]: amplitude.events.epic.Tasks.selectProductTabSuccess,
    [tabs.weather]: amplitude.events.epic.Tasks.selectWeatherTabSuccess
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id === tasksTypes.create || id === tasksTypes.convert) {
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.Tasks.createTaskSuccess,
        {
          page: taskCreationFrom ?? ''
        }
      );
      createAction(dispatch, SET_TASK_CREATION_FROM, undefined);
      return;
    }
    if (id === tasksTypes.duplicate) {
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.Tasks.duplicateTaskSuccess
      );
      return;
    }

    if (
      id !== tasksTypes.create &&
      id !== tasksTypes.convert &&
      id !== tasksTypes.duplicate
    ) {
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.Tasks.editTaskSuccess
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computedDefaultName = isCreateScreen
    ? `${nameInitials} ${yymmddDateString} ${currentMilitaryTime}`
    : '';

  const [selectedApplicationOption, setSelectedApplicationOption] = useState(
    ''
  );

  const getTotalArea = properties => {
    const area = properties.reduce(
      (acc, curr) => {
        const originalArea = curr.areaValue / curr.coveragePct;
        return {
          totalArea: acc.totalArea + curr.areaValue,
          originalTotalArea: acc.originalTotalArea + originalArea
        };
      },
      { totalArea: 0, originalTotalArea: 0 }
    );
    return {
      totalArea: area.totalArea,
      originalTotalArea: area.originalTotalArea,
      coveragePercent: area.totalArea / area.originalTotalArea
    };
  };

  useEffect(() => {
    if (id === 'convert') {
      setIsEditing(false);

      const applicationStrategy =
        context?.planToConvert?.applicationStrategy ||
        APPLICATION_OPTIONS[0].key;

      if (loadInitialValues && context?.planToConvert?.products) {
        Promise.all(
          context.planToConvert.products.map(async prod => getStdUnits(prod))
        ).then(mappedProducts => {
          setInitialValues({
            ...context.planToConvert,
            name: computedDefaultName,
            applicationStrategy,
            products: mappedProducts,
            startDate: getValidDate(context.planToConvert.startDate),
            dueDate: getValidDate(context.planToConvert.dueDate),
            authorizedDate: getValidDate(context.planToConvert.authorizedDate)
          });
        });
      }
      setSelectedApplicationOption(applicationStrategy);
      setLoadInitialValues(false);
      setLoading(false);
    } else if (id === 'create') {
      setIsEditing(false);
      getSelectedCropSeasonIds();

      if (loadInitialValues) {
        setInitialValues(prevState => {
          return {
            ...prevState,
            name: computedDefaultName,
            properties: [],
            products: []
          };
        });
      }

      setSelectedApplicationOption(APPLICATION_OPTIONS[0].key);
      setLoadInitialValues(false);
    } else {
      setIsEditing(true);
      getSelectedCropSeasonIds();
      const idToFetch = id === 'duplicate' ? context.planToConvert.id : id;
      if (loadInitialValues) {
        const { promise } = taskApi.fetch(idToFetch);
        promise
          .then(({ data }) => {
            const area = getTotalArea(data.properties);
            const formattedWeather = [
              data.weather?.find(weather => weather.type === 'START') ||
                DefaultWeatherData[0],
              data.weather?.find(weather => weather.type === 'END') ||
                DefaultWeatherData[1]
            ];
            const dataToSetValues =
              id === 'duplicate'
                ? {
                    ...DEFAULT_TASK_VALUES,
                    // name: `${data.name}-Duplicated`,
                    name: computedDefaultName,
                    applicationStrategy:
                      data.applicationStrategy || APPLICATION_OPTIONS[0].key,
                    tankInformation: data.tankInformation,
                    source: data.source,
                    coveragePercent: area.coveragePercent * 100,
                    totalAreaValue: area.totalArea,
                    totalApplied: area.totalArea,
                    startDate: getValidDate(data.startDate),
                    dueDate: getValidDate(data.dueDate),
                    properties: data.properties.map(property => {
                      return {
                        ...property,
                        coveragePct: property.coveragePct * 100
                      };
                    }),
                    products: data.products.map(product => {
                      return {
                        ...product,
                        coveragePercent: product.coveragePercent * 100
                      };
                    }),
                    weather: formattedWeather,
                    flags: data?.flags,
                    timingEvent: data.timingEvent,
                    authorizedByName: data.authorizedBy,
                    authorizerId: data.authorizerId,
                    applicators: data.applicators,
                    notes: data.notes,
                    type: data.type,
                    authorizedDate: getValidDate(data.authorizedDate)
                  }
                : {
                    ...data,
                    applicationStrategy:
                      data.applicationStrategy || APPLICATION_OPTIONS[0].key,
                    propertiesGroupBy: 'property',
                    startDate: getValidDate(data.startDate),
                    dueDate: getValidDate(data.dueDate),
                    authorizedDate: getValidDate(data.authorizedDate),
                    coveragePercent: area.coveragePercent * 100,
                    totalApplied: area.totalArea,
                    properties: data.properties.map(property => {
                      return {
                        ...property,
                        coveragePct: property.coveragePct * 100
                      };
                    }),
                    products: data.products.map(product => {
                      return {
                        ...product,
                        coveragePercent: product.coveragePercent * 100
                      };
                    }),
                    weather: formattedWeather
                  };
            setSelectedApplicationOption(dataToSetValues.applicationStrategy);
            setInitialValues({
              ...dataToSetValues
              // for now this is only going to be one id since it's a single select.
              // cropSeasonIds: dataToSetValues?.cropSeasonIds[0]?.id || ''
              // weather: setWeather
            });
            setLoadInitialValues(false);
            // setLoading(false);
          })
          .catch(catchCancel)
          .catch(err => {
            parseServerError(dispatch)(err);
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedDefaultName, dispatch, id, loadInitialValues]);

  useEffect(() => {
    const eventToSend = tabEventsMap[amplitudeTabSelection];
    if (eventToSend) {
      amplitude.sendEventToAmplitude(eventToSend);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeTabSelection]);

  useEffect(() => {
    if (!propertiesLoading) {
      setLoading(false);
    }
  }, [propertiesLoading]);

  const getSingleMember = useCallback(async () => {
    const { promise } = organizationApi.fetch();
    await promise
      .then(({ data }) => {
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        setSelectedMember(filterMember);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  useEffect(() => {
    if (!selectedMember) {
      getSingleMember();
    }

    if (
      selectedMember?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(selectedMember, 'financialAccess')
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(selectedMember?.financialAccess);
    }
  }, [selectedMember, getSingleMember]);

  const query = useQuery();

  useEffect(() => {
    const tabQuery = query.get('activeTab');

    if (tabQuery && tabQuery !== activeTab) {
      setActiveTab(tabQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveTab, activeTab, query]);

  useEffect(() => {}, []);

  const getWeather = async values => {
    let updatedWeather = [];
    const startDateIsPast = new Date(values?.startDate).getTime() < Date.now();
    if (
      values?.status === 'complete' &&
      startDateIsPast &&
      context.weatherAutomaticToggle
    ) {
      for (let i = 0; i < 2; i += 1) {
        const coordinates = getCoordinates(
          id,
          values,
          fieldsAndAreasGeoJSONCollection
        );
        const urlDetails = fetchURLDetails(
          new Date(values?.startDate),
          new Date(values?.startDate).getTime(),
          values?.duration,
          i,
          id,
          new Date(values?.dueDate)
        );
        // eslint-disable-next-line no-await-in-loop
        const result = await fetchClearAppWeatherDataReturn(
          new Date(urlDetails.startDateTimeStamp * 1000),
          coordinates,
          urlDetails
        );
        const weatherObj = setWeatherObj(
          i,
          result,
          urlDetails.startDateTimeStamp
        );
        updatedWeather.push(weatherObj);
      }
    } else {
      updatedWeather = values?.weather;
    }
    return values?.weather;
  };

  const handleSave = async formikValues => {
    let amplitudeContext;
    // TODO: add way in UI for user to set this value
    setSaving(true);
    if (id !== 'create') {
      const props = propertyLandingPageData?.properties?.map(prop => {
        return { ...prop, selectedCropSeason: selectedCropSeasonIds };
      });
      const selectedCropZones = getSelectedCropZones(
        props,
        formikValues.properties?.map(cz => cz.cropZoneId),
        fieldsAndAreasGeoJSONCollection,
        formikValues
      );
      const hydratedProps = hydrateProperties(selectedCropZones, formikValues);
      if (hydratedProps !== null) {
        // eslint-disable-next-line no-param-reassign
        formikValues.properties = hydratedProps;
      }
    }
    const farmsAmplitude = formikValues.properties.map(prop => prop.farmId);
    const fieldsAmplitude = formikValues.properties.map(prop => prop.fieldId);
    if (id === 'create' || id === 'duplicate' || id === 'convert') {
      amplitudeContext = amplitude.events.epic.Tasks.createTask;
    } else {
      amplitudeContext = amplitude.events.epic.Tasks.updateTask;
    }

    amplitude.sendEventToAmplitude(amplitudeContext, {
      farmIds: farmsAmplitude,
      fieldIds: fieldsAmplitude
    });
    const newWeather = await getWeather(formikValues);
    const totalAreaUnit = formikValues.properties[0]?.areaUnit || 'acres';

    const authorizedBy = people.find(
      person => person.id === formikValues.authorizerId
    );

    const cropSeasonProperty = new Set();

    const properties = formikValues.properties.map(property => {
      property.cropSeasonIds.forEach(cSId => {
        const res = selectedCropSeasonIds.filter(
          cropSeasonId => cropSeasonId === cSId
        )[0];

        cropSeasonProperty.add(res);
        cropSeasonProperty.delete(undefined);
      });

      return {
        farmId: property.farmId,
        cropZoneId: property.cropZoneId,
        areaUnit: property.areaUnit,
        areaValue: property.areaValue,
        areaType: property.areaType,
        coveragePct: property.coveragePct / 100
      };
    });

    const products = formikValues.products.map(mapProductsToSave);
    const productsWithSavedListPrice = formikValues.products
      .filter(product => product.saveAsNewListPriceDate)
      .map(product => ({
        productId: product.productId,
        averagePricePerUnit: product.averagePriceAtTimeOfCreation,
        productDensity: product.productDensity
      }));
    products.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      delete p.saveAsNewListPriceDate;
    });

    const applicators = formikValues.applicators?.map(applicator => {
      return {
        personId: applicator.personId,
        personName: '',
        companyName: '',
        licenseNumber: '',
        companyId: applicator.companyId,
        expiresDate: ''
      };
    });

    const assigneeIds = formikValues.assigneeIds?.map(assignee => {
      return {
        status: 0,
        message: '',
        id: assignee.id,
        name: ''
      };
    });

    const cropSeasonIds = Array.from(cropSeasonProperty);
    const body = {
      ...!isCreateScreen,
      name: formikValues.name,
      status: formikValues.status,
      notes: formikValues.notes,
      bufferReason: formikValues.bufferReason,
      bufferZone: formikValues.bufferZone,
      sources: [],
      type: formikValues.type,
      duration: formikValues.duration,
      timingEvent: formikValues.timingEvent,
      authorizedDateEpoch: getTime(new Date(formikValues.authorizedDate)),
      authorizedBy: authorizedBy?.name || '',
      authorizerId: formikValues.authorizerId,
      cropSeasonIds: cropSeasonIds.map(cropId => ({ id: cropId })),
      flags: formikValues.flags || [],
      dueDateEpoch: getTime(new Date(formikValues.dueDate)),
      startDateEpoch: getTime(new Date(formikValues.startDate)),
      dueDate: formikValues.dueDate,
      applicators,
      assigneeIds,
      properties,
      totalAreaValue: formikValues.totalAreaValue,
      totalAreaUnit,
      tankInformation: {
        tankSizeValue: formikValues.tankInformation.tankSizeValue,
        tankSizeUnit: 'gallon',
        tankCount: formikValues.tankInformation.tankCount,
        carrierPerAreaValue: formikValues.tankInformation.carrierPerAreaValue,
        carrierPerAreaUnit: 'gallon',
        totalCarrierValue: formikValues.tankInformation.totalCarrierValue,
        totalCarrierUnit: 'gallon',
        tankValueTypeEnteredByUser: 'CarrierPerArea'
      },
      applicationStrategy: formikValues.applicationStrategy,
      products,
      timingEventTag: '',
      totalGrowerCost: 0.0,
      weather: newWeather
        .map((weatherSnapshot, i) => ({
          dateEpoch: getTime(new Date(weatherSnapshot.date)) || '',
          windSpeed: getNumericValue(weatherSnapshot.windSpeed?.toString()),
          windDirection: weatherSnapshot.windDirection || '',
          skyCondition: weatherSnapshot.skyCondition || '',
          temperature: getNumericValue(weatherSnapshot.temperature?.toString()),
          temperatureUnit: weatherSnapshot.temperatureUnit || 'F',
          windSpeedUnit: weatherSnapshot.windSpeedUnit || 'MPH',
          soilMoisture: weatherSnapshot.soilMoisture || 'unknown',
          humidity: getNumericValue(weatherSnapshot.humidity?.toString()) || '',
          type: i === 0 ? 'start' : 'end'
        }))
        .filter(weatherSnapshot => weatherSnapshot.dateEpoch)
    };

    const method = isCreateScreen
      ? taskApi.create(body)
      : taskApi.update(formikValues.taskId, body);

    if (!method?.promise) {
      return;
    }

    await method.promise
      .then(() => {
        if (productsWithSavedListPrice.length > 0) {
          return inventoryAPI.post(productsWithSavedListPrice).promise;
        } else {
          return true;
        }
      })
      .then(() => {
        setSaving(false);
        if (!weatherAutomaticToggle)
          dispatch({
            type: 'SWITCH_WEATHER_TOGGLE'
          });

        if (isComingFromIntegrations) {
          redirectToSubmission();
          return;
        }
        if (amplitudeContext === amplitude.events.epic.Tasks.createTask) {
          amplitudeContext = amplitude.events.epic.Tasks.createTaskSuccess;
        } else if (
          amplitudeContext === amplitude.events.epic.Tasks.updateTask
        ) {
          amplitudeContext = amplitude.events.epic.Tasks.updateTaskSuccess;
        }
        amplitude.sendEventToAmplitude(amplitudeContext, {
          farmIds: farmsAmplitude,
          fieldIds: fieldsAmplitude
        });
        amplitude.sendEventToAmplitude(amplitude.events.epic.Tasks.accessTasks);
        history.push(paths.tasks);
      })
      .catch(catchCancel)
      .catch(err => {
        setSaving(false);
        if (err.response?.data?.validationErrors) {
          if (err.response?.data?.code === 'EARG011') {
            setError(
              'Please select one or more fields in order to save this Task.'
            );
          } else {
            setError(`Error saving task: ${err.response.data.message}`);
          }
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });
  };

  const handleCancel = () => {
    amplitude.sendEventToAmplitude(amplitude.events.epic.Tasks.cancelTask);
    if (isComingFromIntegrations) {
      redirectToSubmission();
      return;
    }

    history.push(paths.tasks);
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Tasks.cancelTaskSuccess
    );
  };

  const formatNumber = value => {
    return (
      <FormattedNumber unit="usCurrency" roundedTo={2}>
        {value || 0}
      </FormattedNumber>
    );
  };
  const getTotalProductCost = products => {
    if (selectedMember?.role === 'Full control' && financialAccess === 'none') {
      return '';
    }
    if (
      selectedMember?.role === 'Admin' ||
      selectedMember?.role === 'View Only' ||
      (selectedMember?.role === 'Full control' &&
        financialAccess === 'WRITE') ||
      (selectedMember?.role === 'Full control' &&
        financialAccess === 'READ' &&
        id !== 'create')
    ) {
      const summation = products
        ?.map(product => product?.totalCostAtTimeOfCreation)
        .reduce((prev, curr) => prev + curr, 0);
      return formatNumber(summation);
    }
    if (
      selectedMember?.role === 'Full control' &&
      financialAccess === 'READ' &&
      id === 'create'
    ) {
      return '';
    }
    return '';
  };
  return (
    <>
      {isComingFromIntegrations ? (
        <Breadcrumb
          disabled
          hideCropSeasonDropdown={false}
          onCropSeasonSelect={() => {
            reloadData();
          }}
        >
          <Breadcrumb.Item
            title="Integrations"
            value={`${query.get('vendor')} Submission`}
            isLast
          />
          <Breadcrumb.Item title="Tasks" value="Edit Task" isLast />
        </Breadcrumb>
      ) : (
        <Breadcrumb
          disabled
          hideCropSeasonDropdown={false}
          onCropSeasonSelect={() => {
            reloadData();
          }}
        >
          <Breadcrumb.Item title="Tasks" value="All Tasks" to={paths.tasks} />
          <Breadcrumb.Item
            title="Task"
            value={isCreateScreen ? 'Create Task' : 'Edit Task'}
            isLast
          />
        </Breadcrumb>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {error && (
            <Toast icon="error" onClose={() => setError('')}>
              {error}
            </Toast>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={values => {
              handleSave(values);
            }}
          >
            {({ values, handleSubmit }) => (
              <div className="h-full">
                <FormNavGuard />
                <div className="flex justify-between px-2 py-4">
                  <div className="flex">
                    <div className="mr-12" data-testid="editViewStartDate">
                      <div className="text-sm leading-4 text-neutral-600 font">
                        Start Date
                      </div>
                      <div className="text-2xl">
                        {format(
                          getTime(new Date(values.startDate)),
                          'MMM d, yyyy'
                        )}
                      </div>
                    </div>
                    <div className="mr-12 " data-testid="editViewAcres">
                      <div className="text-sm leading-4 text-neutral-600 font">
                        Acres
                      </div>
                      <div className="text-2xl">
                        {roundDecimal(values?.totalAreaValue) ||
                          roundDecimal(values?.totalApplied)}
                      </div>
                    </div>
                    <div className="mr-12 " data-testid="editViewProducts">
                      <div className="text-sm leading-4 text-neutral-600 font">
                        Total Cost
                      </div>
                      <div className="text-2xl">
                        {getTotalProductCost(values?.products)}
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-3">
                      <Button
                        type="primary"
                        ghost
                        onClick={handleCancel}
                        id="cancelTask"
                        data-testid="cancelTask"
                        name="cancel"
                      >
                        Cancel
                      </Button>
                    </div>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      id="saveTask"
                      data-testid="saveTask"
                      loading={saving}
                      disabled={saving}
                    >
                      {saving ? 'Saving...' : 'Save'}
                    </Button>
                  </div>
                </div>
                <div
                  className="px-6 pt-4 -mx-4 bg-white"
                  style={{ height: 'calc(100% - 84px)' }}
                >
                  <Tabs activeTab={activeTab}>
                    <Tabs.Tab label="Details">
                      <Details
                        people={people}
                        peopleLoading={peopleLoading}
                        companies={companies}
                        companiesLoaded={hasLoaded}
                        fieldsAndAreasCentroidCoordinates={
                          fieldsAndAreasCentroidCoordinates
                        }
                        fieldsAndAreasGeoJSONCollection={
                          fieldsAndAreasGeoJSONCollection
                        }
                        weatherAutomaticToggle={context.weatherAutomaticToggle}
                        memberRole={selectedMember?.role}
                        memberFinancialAccess={financialAccess}
                        setAmplitudeTabSelection={setAmplitudeTabSelection}
                      />
                    </Tabs.Tab>
                    <Tabs.Tab label="Properties">
                      <Properties
                        propertyLandingPageData={propertyLandingPageData}
                        loading={propertiesLoading}
                        fieldsAndAreasGeoJSONCollection={
                          fieldsAndAreasGeoJSONCollection
                        }
                        cropSeasonIds={selectedCropSeasonIds}
                        selectedApplicationOption={selectedApplicationOption}
                        setAmplitudeTabSelection={setAmplitudeTabSelection}
                      />
                    </Tabs.Tab>
                    <Tabs.Tab label="Products">
                      <Products
                        memberRole={selectedMember?.role}
                        memberFinancialAccess={financialAccess}
                        applicationOptions={APPLICATION_OPTIONS}
                        selectedApplicationOption={selectedApplicationOption}
                        setSelectedApplicationOption={
                          setSelectedApplicationOption
                        }
                        isEditing={isEditing}
                        setAmplitudeTabSelection={setAmplitudeTabSelection}
                      />
                    </Tabs.Tab>
                    <Tabs.Tab label="Weather">
                      <WeatherTab
                        fieldsAndAreasCentroidCoordinates={
                          fieldsAndAreasCentroidCoordinates
                        }
                        fieldsAndAreasGeoJSONCollection={
                          fieldsAndAreasGeoJSONCollection
                        }
                        setAmplitudeTabSelection={setAmplitudeTabSelection}
                      />
                    </Tabs.Tab>
                  </Tabs>
                </div>
              </div>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default Task;
