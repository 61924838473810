import {
  difference,
  lineToPolygon,
  lineOffset,
  lineIntersect,
  getCoords,
  booleanPointInPolygon
} from '@turf/turf';
import { polygon, featureCollection, point, lineString } from '@turf/helpers';

const THICK_LINE_UNITS = 'kilometers';
const THICK_LINE_WIDTH = 0.001;

const areLineAndPolygonValid = (line, polygonFeature) => {
  return (
    (polygonFeature.type === 'Polygon' ||
      polygonFeature.type === 'MultiPolygon') &&
    line.type === 'LineString'
  );
};

const doLineAndPolygonIntersect = (line, polygonFeature) => {
  const intersectPoints = lineIntersect(polygonFeature, line);
  return intersectPoints.features.length > 0;
};

const areLineEndpointsOutsidePolygon = (line, polygonFeature) => {
  const lineCoords = getCoords(line);
  return (
    !booleanPointInPolygon(point(lineCoords[0]), polygonFeature) &&
    !booleanPointInPolygon(
      point(lineCoords[lineCoords.length - 1]),
      polygonFeature
    )
  );
};

// to pick the polygons that have separate borders, pick the polygon that doesn't intersect the select line
// to pick the polygons that share borders, pick the polygon that does intersect the select line
const meetsSeparateBordersCriteria = (
  line,
  polygonFeature,
  separateBorders
) => {
  const intersect = lineIntersect(polygonFeature, line);
  return (
    (separateBorders && intersect.features.length === 0) ||
    (!separateBorders && intersect.features.length > 0)
  );
};

// take 2 parallel lines and turn them into a thick line polygon
const createThickLinePolygon = (line, offsetLine) => {
  const polyCoords = [];
  for (let j = 0; j < line.coordinates.length; j += 1) {
    polyCoords.push(line.coordinates[j]);
  }
  for (let j = offsetLine.geometry.coordinates.length - 1; j >= 0; j -= 1) {
    polyCoords.push(offsetLine.geometry.coordinates[j]);
  }
  polyCoords.push(line.coordinates[0]);

  const thickLineString = lineString(polyCoords);
  return lineToPolygon(thickLineString);
};

// https://gis.stackexchange.com/questions/344068/splitting-a-polygonFeature-by-multiple-linestrings-leaflet-and-turf-js
const polygonCut = (polygonFeature, line, separateBorders = false) => {
  let forCut;
  let forSelect;
  let thickLinePolygonFeature;
  let clipped;
  let cutPolygon;

  let cutPolyGeoms = [];
  const cutFeatures = [];
  const offsetLine = [];

  let returnValue = null;

  if (
    !areLineAndPolygonValid(line, polygonFeature) ||
    !doLineAndPolygonIntersect(line, polygonFeature) ||
    !areLineEndpointsOutsidePolygon(line, polygonFeature)
  ) {
    return returnValue;
  }

  offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS
  });
  offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS
  });

  for (let i = 0; i <= 1; i += 1) {
    forCut = i;
    forSelect = (i + 1) % 2;
    thickLinePolygonFeature = createThickLinePolygon(line, offsetLine[forCut]);
    clipped = difference(polygonFeature, thickLinePolygonFeature);

    cutPolyGeoms = [];
    // pick the polygons from the resulting multipolygon that meet the specified separateBorders criteria
    for (let j = 0; j < clipped.geometry.coordinates.length; j += 1) {
      cutPolygon = polygon(clipped.geometry.coordinates[j]);
      if (
        meetsSeparateBordersCriteria(
          offsetLine[forSelect],
          cutPolygon,
          separateBorders
        )
      ) {
        cutPolyGeoms.push(cutPolygon.geometry.coordinates);
      }
    }

    cutPolyGeoms.forEach(geometry => {
      cutFeatures.push(polygon(geometry));
    });
  }

  if (cutFeatures.length > 0) returnValue = featureCollection(cutFeatures);

  return returnValue;
};

export default polygonCut;
