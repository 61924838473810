/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SmartlookClient from 'smartlook-client';
import { shouldUseMirage } from 'utilities/apiConstants';
import * as DatadogManager from './utilities/datadog/datadogManager';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import '@agconnections/grow-ui/dist/main.css';
import { ProvideAuth, cleanNewUserSession } from './utilities/base-auth';
import { AmplitudeContextProvider } from './utilities/amplitude/useAmplitude';
import './tailwind.css';
import './index.css';

function getGitTagNameOnly(str) {
  // Replace /, ., and - with an empty string to trim the build tag meeting sentry standards
  return str?.length > 0 ? str.replace(/[/.-]/g, '') : 'v1.0.0';
}

Sentry.init({
  release: getGitTagNameOnly(process.env.SENTRY_CODEBUILD_WEBHOOK_TRIGGER),
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE) || 0.1
});

DatadogManager.initialize();

SmartlookClient.init(process.env.REACT_APP_SMART_LOOK_KEY, {
  region: process.env.REACT_APP_SMART_LOOK_REGION,
  version: process.env.REACT_APP_SMART_LOOK_VERSION
});

let startMirage = false;
if (shouldUseMirage) {
  startMirage = true;
}

if (startMirage) {
  /* eslint-disable global-require */
  const { makeServer } = require('./mirage');
  makeServer({ environment: process.env.REACT_APP_ENV });
}
cleanNewUserSession(); // if new login on accounts portal, clear session on main app

ReactDOM.render(
  <AmplitudeContextProvider>
    <ProvideAuth>
      <App />
    </ProvideAuth>
  </AmplitudeContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
