import Decimal from 'decimal.js';
import { Maths } from 'helpers/maths';

export const roundPercentToArea = (percent, area, round = 2) => {
  return Maths.parseFloatRound(
    (percent / 100) * area,
    round,
    Decimal.ROUND_HALF_UP
  );
};

export const roundAreaToPercent = (area, totalArea, round = 2) => {
  return Maths.parseFloatRound(
    (area / totalArea) * 100,
    round,
    Decimal.ROUND_HALF_UP
  );
};

export const hydrateProperties = (selectedCropzones, values) => {
  // this entire function needs to be simplified and testable
  // setNoCropSeason(values?.cropSeasonIds === undefined);
  const sanitizedArray = [];
  const czIds = selectedCropzones.map(cz => cz.id);
  const existingProperties = values.properties
    .filter(property => czIds.includes(property.cropZoneId))
    .filter(property => property.hydrated === true);
  const existingPropertyIds = existingProperties.map(
    property => property.cropZoneId
  );
  const propertiesIdsToAdd = existingPropertyIds.filter(x => czIds.includes(x));

  const getAreaValue = zone => {
    let area = Math.round((zone.boundaryArea + Number.EPSILON) * 100) / 100;
    if (zone.areaValue) {
      area = zone.areaValue;
    } else if (zone.reportedArea > 0) {
      area = Math.round((zone.reportedArea + Number.EPSILON) * 100) / 100;
    }
    return area;
  };
  selectedCropzones
    .filter(cz => !propertiesIdsToAdd.includes(cz.id))
    .forEach(cropzone => {
      const obj = {
        farmId: cropzone.farmId,
        fieldId: cropzone.fieldId,
        cropZoneId: cropzone.id,
        cropSeasonIds: cropzone.cropSeasons.map(season => season.id),
        areaUnit: 'acres',
        originalAcres:
          cropzone.areaValue && cropzone.reportedArea > 0
            ? Math.round((cropzone.reportedArea + Number.EPSILON) * 100) / 100
            : Math.round((cropzone.boundaryArea + Number.EPSILON) * 100) / 100,
        areaValue: getAreaValue(cropzone),
        coveragePct: cropzone.coveragePct || 100,
        boundaryArea:
          Math.round((cropzone.boundaryArea + Number.EPSILON) * 100) / 100,
        reportedArea:
          Math.round((cropzone.reportedArea + Number.EPSILON) * 100) / 100,
        areaType: cropzone.areaType || 'reported',
        name: cropzone.name,
        crop: cropzone.crop,
        features: cropzone.features,
        fieldName: cropzone.fieldName,
        hydrated: true
      };
      sanitizedArray.push(obj);
    });
  if (existingProperties.length > 0 || sanitizedArray.length > 0) {
    return [...existingProperties.slice(), ...sanitizedArray];
  }
  return null;
};
export const getSelectedCropZones = (
  properties,
  selectedCropzoneIds,
  fieldsAndAreasGeoJSONCollection,
  values
) => {
  const selectedCropzoneData = [];
  if (properties?.length > 0) {
    properties.forEach(property => {
      if (property.fields.length > 0) {
        property.fields.forEach(field => {
          field.cropzones.forEach(cz => {
            const matchingProperty = values.properties.find(
              prop => prop.cropZoneId === cz.id
            );
            if (selectedCropzoneIds?.includes(cz.id)) {
              selectedCropzoneData.push({
                ...cz,
                farmId: matchingProperty?.farmId || property.id,
                fieldName: field.name,
                fieldId: field.id,
                features: fieldsAndAreasGeoJSONCollection.features?.filter(
                  feature => field.id === feature.properties.id
                ),
                areaType: matchingProperty?.areaType || 'reported',
                areaValue: matchingProperty?.areaValue,
                coveragePct: matchingProperty
                  ? matchingProperty.coveragePct
                  : 100
              });
            }
          });
        });
      }
    });
  }
  return selectedCropzoneData;
};
