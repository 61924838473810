import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import useMassAssignerTools from 'screens/Property/hooks/useMassAssignerTools';
import groupByCrop from 'screens/Property/helpers/groupByCrops';
import AddCropZonesModal from '../AddCropZonesModal';
import MassAssignerActions from '../MassAssignerActions';
import ExportPDFMapsFooter from '../../../PanelFooter/components/ExportPDFMapsFooter';
import GroupByCrop from './components/GroupByCrop';
import GroupByFarm from './components/GroupByFarms';

const MassAssignerTree = ({
  properties,
  fieldsAndAreasGeoJSONCollection,
  propertiesFilter
}) => {
  const [
    {
      fieldsToMassiveAssign,
      searchTextValueFFT,
      isExportPDFMapsActive,
      isSortByCrop
    },
    dispatch
  ] = useContext(Context);

  let processedProperties = properties.map(property => ({
    ...property,
    fields: property.fields.map(field => ({
      ...field,
      farmId: property.id,
      farmName: property.name
    }))
  }));
  processedProperties = isSortByCrop
    ? groupByCrop(processedProperties)
    : propertiesFilter(processedProperties, searchTextValueFFT);

  const {
    handleSelectedFarm,
    handleSelectedField,
    isFarmSelected,
    isFieldSelected,
    isCropZoneSelected,
    handleSelectedCropZone,
    handleSelectAllProperties,
    selectedAllProperties,
    isCropGroupSelected,
    handleSelectedCropGroup,
    handleSelectedFarmInCropGroup,
    handleSelectedFieldInCropGroup,
    isFarmSelectedInCropGroup,
    isFieldSelectedInCropGroup
  } = useMassAssignerTools(
    processedProperties,
    fieldsToMassiveAssign,
    dispatch,
    isSortByCrop
  );
  const [openAddCropZonesModal, setOpenAddCropZonesModal] = useState(false);
  return (
    <div
      data-tree
      className="relative h-full flex-grow flex flex-col w-full overflow-y-auto"
      data-testid="mass-assigner-tree"
    >
      <div className="px-1 overflow-y-auto flex-grow flex-wrap overflow-x-hidden">
        <Table>
          <Table.Row
            onClick={handleSelectAllProperties}
            selectable
            selected={selectedAllProperties}
          >
            <Table.Cell>
              <div className="font-semibold text-sm">
                {searchTextValueFFT?.valueInput?.length > 2
                  ? 'Select all results'
                  : 'Select All Properties'}
              </div>
            </Table.Cell>
          </Table.Row>
          {isSortByCrop
            ? processedProperties.map(crop => (
                <GroupByCrop
                  key={crop.id}
                  crop={crop}
                  properties={properties}
                  handleSelectedCropGroup={handleSelectedCropGroup}
                  fieldsAndAreasGeoJSONCollection={
                    fieldsAndAreasGeoJSONCollection
                  }
                  isCropGroupSelected={isCropGroupSelected}
                  handleSelectedFarmInCropGroup={handleSelectedFarmInCropGroup}
                  isFarmSelectedInCropGroup={isFarmSelectedInCropGroup}
                  handleSelectedFieldInCropGroup={
                    handleSelectedFieldInCropGroup
                  }
                  isFieldSelectedInCropGroup={isFieldSelectedInCropGroup}
                  handleSelectedCropZone={handleSelectedCropZone}
                  isCropZoneSelected={isCropZoneSelected}
                />
              ))
            : processedProperties.map(property => (
                <GroupByFarm
                  key={property.id}
                  property={property}
                  fieldsAndAreasGeoJSONCollection={
                    fieldsAndAreasGeoJSONCollection
                  }
                  handleSelectedFarm={handleSelectedFarm}
                  isFarmSelected={isFarmSelected}
                  handleSelectedField={handleSelectedField}
                  isFieldSelected={isFieldSelected}
                  handleSelectedCropZone={handleSelectedCropZone}
                  isCropZoneSelected={isCropZoneSelected}
                />
              ))}
        </Table>
      </div>
      {isExportPDFMapsActive ? (
        <ExportPDFMapsFooter step={0} selectedFields={fieldsToMassiveAssign} />
      ) : (
        <MassAssignerActions
          dispatch={dispatch}
          fieldsToMassiveAssign={fieldsToMassiveAssign}
          setOpenAddCropZonesModal={setOpenAddCropZonesModal}
        />
      )}
      {openAddCropZonesModal && (
        <AddCropZonesModal
          open={openAddCropZonesModal}
          onClose={() => setOpenAddCropZonesModal(false)}
        />
      )}
    </div>
  );
};
MassAssignerTree.defaultProps = {
  properties: [],
  fieldsAndAreasGeoJSONCollection: {}
};
MassAssignerTree.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.any),
  propertiesFilter: PropTypes.func.isRequired
};
export default MassAssignerTree;
