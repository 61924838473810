import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBack as SVGArrowBack } from '@material-ui/icons';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import ClickableDiv from 'components/ClickableDiv';
import { Context } from 'components/Store';
import { SET_SHOW_TOAST_CROP_ZONE_MATCHER } from 'reducers/reducer';
import Toast from 'components/Toast';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';
import SourceFieldList from './components/SourceFieldList';
import SourceOperationList from './components/SourceOperationList';
import useJohnDeereData from '../hooks/useJohnDeereData';
import { CropZoneMatcherContext } from './context/CropZoneMatcherProvider';
import displayTextForToast, {
  toastTitle
} from '../helpers/displayTextForToast';
import CheckPackageWeight from './components/CheckPackageWeight';

const CropZoneMatcher = () => {
  const [selectedSourceField, setSelectedSourceField] = useState();
  const [selectedSeasons, setSelectedSeasons] = useState(getSeasons());
  const [sourceFieldList, setSourceFieldList] = useState([]);
  const history = useHistory();
  const [
    {
      cropZoneMatcherType,
      showToastCropZoneMatcher,
      additionalInformationToastCropZoneMatcher
    },
    dispatch
  ] = useContext(Context);

  const [openSuccessToast, setOpenSuccessToast] = useState(
    showToastCropZoneMatcher
  );
  const {
    loadMappedOperations,
    isLoadingMappedOperations,
    mappedOperations,
    since,
    setSince,
    until,
    setUntil
  } = useJohnDeereData();

  const { reloadData } = useContext(CropZoneMatcherContext);

  const {
    operationMapped,
    cropZoneName,
    message
  } = additionalInformationToastCropZoneMatcher;

  const handleURLRedirect = () => {
    history.goBack();
  };
  const handleOrgChange = () => {
    loadMappedOperations();
  };

  const applyDateFilter = () => {
    loadMappedOperations();
  };

  const handleCropSeasonChange = () => {
    setSelectedSeasons(getSeasons());
  };

  useEffect(() => {
    setSourceFieldList(mappedOperations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedOperations]);

  useEffect(() => {
    loadMappedOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  useEffect(() => {
    if (!sourceFieldList?.length) {
      setSelectedSourceField(null);
    } else {
      setSelectedSourceField(sourceFieldList[0]);
    }
  }, [sourceFieldList]);

  useEffect(() => {
    setOpenSuccessToast(showToastCropZoneMatcher);
  }, [showToastCropZoneMatcher]);

  return (
    <>
      <Breadcrumb
        onOrganizationSelect={handleOrgChange}
        hideCropSeasonDropdown={false}
        hideOrganizationDropdown={false}
        onCropSeasonSelect={handleCropSeasonChange}
        disabled
      >
        <Breadcrumb.Item
          title="Integrations"
          value="Crop Zone Matching"
          isLast
        />
      </Breadcrumb>
      <div
        data-testid="crop-zone-matcher-landing"
        className="flex flex-row w-full h-full"
      >
        <div className="flex flex-col w-123">
          <ClickableDiv className="pl-24px" onClick={handleURLRedirect}>
            <div className="flex h-56px items-center">
              <SVGArrowBack fill="696F88" />
              <span className="h-28px ml-20px text-xl font-normal">
                Crop Zone Matching
              </span>
            </div>
          </ClickableDiv>
          <div className="bg-white" style={{ height: 'calc(100% - 56px)' }}>
            <CheckPackageWeight />
            <SourceFieldList
              since={since}
              until={until}
              setSince={setSince}
              setUntil={setUntil}
              applyDateFilter={applyDateFilter}
              isLoading={isLoadingMappedOperations}
              sourceFields={sourceFieldList}
              selectedSourceField={selectedSourceField}
              onChange={setSelectedSourceField}
            />
          </div>
        </div>
        <div className="w-full h-full">
          <SourceOperationList
            selectedSourceField={selectedSourceField}
            isLoadingOperations={isLoadingMappedOperations}
            selectedSeasons={selectedSeasons}
          />
        </div>

        <Toast
          type={cropZoneMatcherType}
          title={`${toastTitle(cropZoneMatcherType)} ${cropZoneName ||
            'Crop Zone'}`}
          open={openSuccessToast}
          onClose={() => {
            dispatch({
              type: SET_SHOW_TOAST_CROP_ZONE_MATCHER,
              payload: false
            });
          }}
          timeout={60000}
        >
          <div className="text-sm font-body">
            {`${operationMapped} ${displayTextForToast(cropZoneMatcherType)} `}
            <div className="whitespace-pre-line">{message}</div>
          </div>
        </Toast>
      </div>
    </>
  );
};

export default CropZoneMatcher;
