import { Col, Row } from 'antd';
import { flatten } from 'lodash';
import React from 'react';

const renderLegendLabelRows = ({ fieldsToMassiveAssign, mapLegendLabels }) => {
  const properties = flatten(
    fieldsToMassiveAssign.map(field =>
      field.cropzones.length ? field.cropzones : field
    )
  );
  const propertyIndexes = Array.from(
    {
      length: new Set(properties).size
    },
    (_, i) => i + 1
  );

  return propertyIndexes.map(index => (
    <Row key={mapLegendLabels[index]}>
      <Col className="w-4 mr-6px text-right text-12pt">{index}.</Col>
      <Col className="text-12pt" span={23}>
        {mapLegendLabels[index]?.replace(/\n/g, ' / ')}
      </Col>
    </Row>
  ));
};

export default renderLegendLabelRows;
