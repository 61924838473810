import { SimpleModal, RadioButtonGroup, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { roundAreaToPercent, roundPercentToArea } from '../../helpers';

const EditPropertyModal = ({ editingProperty, setEditingProperty }) => {
  const { values, setFieldTouched } = useFormikContext();
  const { RadioButton } = RadioButtonGroup;
  return (
    <>
      {editingProperty && (
        <FieldArray name="properties">
          {({ replace }) => (
            <SimpleModal
              data-testid="edit-property-modal"
              confirmLabel="Save"
              open={editingProperty}
              onConfirm={() => {
                setFieldTouched('save cropzone');
                let areaValue = editingProperty?.areaValue;
                let areaType = editingProperty?.areaType;
                let coveragePct = editingProperty?.coveragePct;
                if (
                  editingProperty.areaType === 'reported' &&
                  !editingProperty.reportedArea &&
                  (!coveragePct || !areaValue)
                ) {
                  areaValue = editingProperty?.boundaryArea;
                  areaType = 'boundary';
                  coveragePct = 100;
                }
                replace(
                  values.properties
                    .map(property => property.cropZoneId)
                    .indexOf(editingProperty?.cropZoneId),
                  {
                    ...editingProperty,
                    areaType,
                    areaValue,
                    coveragePct
                  }
                );
                setEditingProperty(null);
              }}
              close={() => setEditingProperty(null)}
            >
              <div className="ml-6">
                <div className="mb-6">
                  <div className="text-xl font-bold">
                    {editingProperty?.fieldName}
                  </div>
                  <span className="text-sm text-neutral-300">
                    {editingProperty?.name}
                  </span>
                </div>
                <div className="flex mb-6">
                  <div className="mr-6">
                    <span className="text-sm text-neutral-300">Area</span>
                    <div className="text-xl">
                      {editingProperty?.originalAcres} ac
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-neutral-300">Crop</span>
                    <div className="text-xl ">
                      {editingProperty?.crop?.name}
                    </div>
                  </div>
                </div>
                <div className="mb-12">
                  <GrowUIFormField
                    control={RadioButtonGroup}
                    name="areaReported"
                    selected={editingProperty.areaType}
                    onChange={e => {
                      const _area =
                        e === 'boundary'
                          ? editingProperty?.boundaryArea
                          : editingProperty?.reportedArea;
                      setEditingProperty({
                        ...editingProperty,
                        areaType: e,
                        areaValue: _area,
                        coveragePct: roundAreaToPercent(
                          _area,
                          e === 'boundary'
                            ? editingProperty?.boundaryArea
                            : editingProperty?.reportedArea
                        )
                      });
                    }}
                  >
                    <GrowUIFormField
                      value="reported"
                      id="reported"
                      name="areaReported"
                      control={RadioButton}
                    >
                      <span className="truncate w-48">
                        Reported Area ({editingProperty?.reportedArea} ac)
                      </span>
                    </GrowUIFormField>
                    <GrowUIFormField
                      value="boundary"
                      id="boundary"
                      name="areaReported"
                      control={RadioButton}
                    >
                      <span className="truncate w-48">
                        Boundary Area ({editingProperty?.boundaryArea} ac)
                      </span>
                    </GrowUIFormField>
                  </GrowUIFormField>
                </div>
                <div className="flex">
                  <div className="mr-4">
                    <GrowUIFormField
                      label="Area Applied"
                      control={Input}
                      value={editingProperty?.areaValue}
                      onBlur={e => {
                        const area = Number(e.target.value);
                        setEditingProperty({
                          ...editingProperty,
                          areaValue: area,
                          coveragePct: roundAreaToPercent(
                            area,
                            editingProperty?.areaType === 'boundary'
                              ? editingProperty?.boundaryArea
                              : editingProperty?.reportedArea
                          )
                        });
                      }}
                      name="zoneAcres"
                      imask={{
                        mask: 'num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            scale: 2,
                            signed: false,
                            thousandsSeparator: '',
                            padFractionalZeros: false,
                            normalizeZeros: true,
                            radix: '.',
                            mapToRadix: [','],
                            min: 0
                          }
                        }
                      }}
                    />
                  </div>
                  <GrowUIFormField
                    label="Coverage %"
                    control={Input}
                    name="zoneCoveragePercent"
                    value={editingProperty?.coveragePct}
                    onBlur={e => {
                      const coverage = Number(e.target.value);
                      setEditingProperty({
                        ...editingProperty,
                        coveragePct: coverage,
                        areaValue: roundPercentToArea(
                          coverage,
                          editingProperty?.areaType === 'boundary'
                            ? editingProperty?.boundaryArea
                            : editingProperty?.reportedArea
                        )
                      });
                    }}
                    imask={{
                      mask: 'num',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          signed: false,
                          thousandsSeparator: '',
                          padFractionalZeros: false,
                          normalizeZeros: false,
                          radix: '.',
                          mapToRadix: [','],
                          min: 0
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </SimpleModal>
          )}
        </FieldArray>
      )}
    </>
  );
};

EditPropertyModal.propTypes = {
  editingProperty: PropTypes.shape({
    cropZoneId: PropTypes.string,
    areaValue: PropTypes.number,
    areaType: PropTypes.string,
    coveragePct: PropTypes.number,
    reportedArea: PropTypes.number,
    boundaryArea: PropTypes.number,
    fieldName: PropTypes.string,
    name: PropTypes.string,
    originalAcres: PropTypes.number,
    crop: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  setEditingProperty: PropTypes.func.isRequired
};

export default EditPropertyModal;
