import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { format } from 'date-fns';
import Truncate from 'react-truncate';

import { Context } from 'components/Store';
import { CropZoneIcon, FarmIcon } from 'components/Icons';
import OrganizationIcon from 'components/Icons/components/OrganizationIcon';
import { filterUniqueItems } from 'screens/Property/helpers/propertyDataHelpers';
import { MAP_PDF_SCALE } from 'screens/Property/helpers/mapApiHelpers';

const flexRowClass = 'flex flex-row w-full';
const flexRowFullClass = 'flex flex-row w-full items-center';
const wFullClass = 'w-full';
const commaSpace = ',\u00A0';

const PropertyMapFooter = () => {
  const [
    { propertyMapFooterConfig, fieldsToMassiveAssign, organization }
  ] = useContext(Context);

  const [generatedDate, setGeneratedDate] = useState('');
  const [isTruncatedText, setIsTruncatedText] = useState(false);
  const cropSeasonsRef = useRef(null);
  const farmNamesRef = useRef(null);

  const cropSeasons = useMemo(
    () =>
      filterUniqueItems(fieldsToMassiveAssign, field =>
        field?.cropzones.flatMap(cropZone => cropZone?.cropSeasons ?? [])
      ),
    [fieldsToMassiveAssign]
  );
  const farmNames = useMemo(
    () => filterUniqueItems(fieldsToMassiveAssign, field => [field?.farmName]),
    [fieldsToMassiveAssign]
  );

  useEffect(() => {
    const date = new Date();
    const formattedDate = format(date, 'MMM dd, yyyy hh:mm a');
    setGeneratedDate(`Generated on ${formattedDate}`);
  }, []);

  return (
    <div
      data-testid="footer-preview"
      className="flex flex-col"
      style={{
        width: `calc(100% - ${216 * MAP_PDF_SCALE}px)`,
        paddingRight: `${16 * MAP_PDF_SCALE}px`,
        height: `${72 * MAP_PDF_SCALE}px`,
        marginTop: 4 * MAP_PDF_SCALE
      }}
    >
      <div
        className="flex flex-col items-start"
        style={{
          fontSize: `${10 * MAP_PDF_SCALE}pt`
        }}
      >
        {propertyMapFooterConfig.organizationName && (
          <div className={flexRowClass} data-testid="organization-name">
            <OrganizationIcon size={12 * MAP_PDF_SCALE} />
            <Truncate
              lines={1}
              ellipsis={<span>...</span>}
              onTruncate={() => setIsTruncatedText(true)}
              style={{
                ...(isTruncatedText
                  ? { fontSize: `${8 * MAP_PDF_SCALE}px` }
                  : {}),
                marginLeft: 4 * MAP_PDF_SCALE,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span
                className={wFullClass}
                style={
                  isTruncatedText ? { fontSize: `${8 * MAP_PDF_SCALE}px` } : {}
                }
              >
                {organization.name}
              </span>
            </Truncate>
          </div>
        )}
        <div
          className={flexRowClass}
          style={isTruncatedText ? { fontSize: `${8 * MAP_PDF_SCALE}px` } : {}}
        >
          {propertyMapFooterConfig.farmNames && (
            <div className={flexRowClass} data-testid="farm-names">
              <FarmIcon size={12 * MAP_PDF_SCALE} />
              <span
                ref={farmNamesRef}
                className={`${wFullClass}`}
                style={{
                  marginLeft: 4 * MAP_PDF_SCALE
                }}
              >
                <Truncate
                  lines={2}
                  ellipsis={<span>...</span>}
                  onTruncate={() => setIsTruncatedText(true)}
                >
                  {farmNames.map((item, index) => (
                    <span key={item}>
                      {item}
                      {index < farmNames.length - 1 && commaSpace}
                    </span>
                  ))}
                </Truncate>
              </span>
            </div>
          )}
        </div>
        <div
          className={flexRowFullClass}
          style={isTruncatedText ? { fontSize: `${8 * MAP_PDF_SCALE}px` } : {}}
        >
          {propertyMapFooterConfig.cropSeasonNames && (
            <div className={flexRowClass} data-testid="crop-seasons">
              <CropZoneIcon size={12 * MAP_PDF_SCALE} />
              <span
                ref={cropSeasonsRef}
                className={`${wFullClass}`}
                style={{
                  marginLeft: 4 * MAP_PDF_SCALE
                }}
              >
                <Truncate
                  lines={2}
                  ellipsis={<span>...</span>}
                  onTruncate={() => setIsTruncatedText(true)}
                >
                  {cropSeasons.map((item, index) => (
                    <span key={item.id}>
                      {item.name}
                      {index < cropSeasons.length - 1 && commaSpace}
                    </span>
                  ))}
                </Truncate>
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className="mt-auto"
        style={isTruncatedText ? { fontSize: `${8 * MAP_PDF_SCALE}px` } : {}}
      >
        {generatedDate}
      </div>
    </div>
  );
};

export default PropertyMapFooter;
