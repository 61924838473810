/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import useJohnDeereData from 'screens/Integrations/hooks/useJohnDeereData';
import { Context } from 'components/Store';
import {
  SET_CROP_ZONE_MATCHER_INFO,
  SET_CROP_ZONE_MATCHER_TYPE,
  SET_SHOW_TOAST_CROP_ZONE_MATCHER
} from 'reducers/reducer';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const CropZoneMatcherContext = createContext();

const CropZoneMatcherProvider = ({ children }) => {
  const [operationIds, setOperationIds] = useState([]);
  const [fieldId, setCropZoneId] = useState();
  const [fieldsGeometries, setFieldsGeometries] = useState(new Map());
  const [cropSeasonId, setCropSeasonId] = useState();
  const [cropZoneName, setCropZoneName] = useState();
  const [isLoadingMatcher, setIsLoadingMatcher] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const amplitude = useContext(AmplitudeContext);

  const { loadMatchCropZone } = useJohnDeereData();

  const [, dispatch] = useContext(Context);

  const getCs = cropSeason => {
    setCropSeasonId(cropSeason);
  };

  const getOperationId = oId => {
    setOperationIds(prev =>
      [...prev, oId].filter(
        (item, index) => [...prev, oId].indexOf(item) === index
      )
    );
  };

  const removeOperationId = oId => {
    setOperationIds(prev => [...prev].filter(item => item !== oId));
  };

  const operationReset = () => {
    setOperationIds([]);
  };

  const getCropZoneId = cropZiD => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Integrations.JohnDeere.selectCropZone
    );
    setCropZoneId(cropZiD);
  };

  const reloadAllData = () => {
    setReloadData(prev => !prev);
  };

  const getCropZoneName = cropName => {
    setCropZoneName(cropName);
  };

  const buttonIsLoading = () => {
    setIsLoadingMatcher(true);
  };

  const buttonIsLoadingIsgDisable = () => {
    setIsLoadingMatcher(false);
  };

  const extractFieldsGeometriesFromFarms = farms => {
    if (fieldsGeometries.size === 0 && farms?.length > 0) {
      setFieldsGeometries(
        new Map(
          farms.flatMap(farm =>
            farm.fields?.map(({ id, geometry }) => [id, geometry])
          )
        )
      );
    }
  };

  const showToast = useCallback(
    (type, operationMapped, message) => {
      dispatch({
        type: SET_CROP_ZONE_MATCHER_TYPE,
        payload: type
      });
      dispatch({
        type: SET_CROP_ZONE_MATCHER_INFO,
        payload: {
          cropZoneName,
          operationMapped,
          message
        }
      });
      dispatch({
        type: SET_SHOW_TOAST_CROP_ZONE_MATCHER,
        payload: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cropZoneName]
  );

  const evaluateBulkResponse = useCallback(
    response => {
      const messages = (Array.isArray(response.data)
        ? response.data
        : [response.data]
      )
        .filter(Boolean)
        .map(({ message }) => message);
      const detailedMessage = [...new Set(messages)].join('\n');
      const sendAmplitudeEvents = (event, additionalData = {}) => {
        amplitude.sendEventToAmplitude(event, {
          operationsMatched: operationIds.length,
          ...additionalData
        });
      };

      switch (response.status) {
        case 201:
          sendAmplitudeEvents(
            amplitude.events.epic.Integrations.JohnDeere.operationsMatched
          );
          sendAmplitudeEvents(
            amplitude.events.epic.Integrations.JohnDeere.cropZoneMatchSuccess
          );
          showToast(
            'success',
            operationIds.length,
            `Task(s) were created with title: ${detailedMessage}`
          );
          break;
        case 207: {
          sendAmplitudeEvents(
            amplitude.events.epic.Integrations.JohnDeere.operationsMatched
          );
          sendAmplitudeEvents(
            amplitude.events.epic.Integrations.JohnDeere
              .cropZoneMatchPartialSuccess
          );

          const fails = response.data.filter(({ status }) => status !== 201);
          const success = response.data.filter(({ status }) => status === 201);
          const successMessages = [
            ...new Set(success.map(({ message }) => message))
          ].join(', ');
          const failMessages = fails.map(({ message }) => message).join('\n\n');
          showToast(
            'warning',
            success.length,
            `Task(s) were created with title: ${successMessages}\n\nBut ${fails.length} operation(s) were not able to be matched. Failed with the error(s):\n\n${failMessages}`
          );
          break;
        }
        default:
          if (response.status >= 400) {
            sendAmplitudeEvents(
              amplitude.events.epic.Integrations.JohnDeere.cropZoneMatchFailure
            );
            showToast('error', operationIds.length, `\n${detailedMessage}`);
          }
          break;
      }
      reloadAllData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [operationIds]
  );

  const toMatchCropZone = async () => {
    if (!cropSeasonId || !operationIds || !fieldId) {
      buttonIsLoadingIsgDisable();
      return;
    }
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions();
    const currentTimeUTC = new Date();
    const creationDate = currentTimeUTC.toLocaleString(userTimeZone.locale, {
      timeZone: `${userTimeZone.timeZone}`,
      hourCycle: 'h23',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    const response = await loadMatchCropZone(
      operationIds.map(operationId => ({
        operationId,
        cropzoneId: fieldId,
        cropseasonId: cropSeasonId,
        creationDate
      }))
    );
    evaluateBulkResponse(response);
    buttonIsLoadingIsgDisable();
  };

  const pro = useMemo(
    () => ({
      getOperationId,
      operationReset,
      removeOperationId,
      fieldId,
      setCropZoneId,
      cropSeasonId,
      toMatchCropZone,
      getCs,
      getCropZoneId,
      operationIds,
      getCropZoneName,
      isLoadingMatcher,
      buttonIsLoading,
      reloadData,
      extractFieldsGeometriesFromFarms,
      fieldsGeometries
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cropSeasonId,
      fieldId,
      operationIds,
      isLoadingMatcher,
      reloadData,
      fieldsGeometries
    ]
  );

  return (
    <CropZoneMatcherContext.Provider value={pro}>
      {children}
    </CropZoneMatcherContext.Provider>
  );
};

CropZoneMatcherProvider.defaultProps = {
  children: null
};

CropZoneMatcherProvider.propTypes = {
  children: PropTypes.node
};

export { CropZoneMatcherContext, CropZoneMatcherProvider };
