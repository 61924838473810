import formatNumber from 'helpers/formatNumber';

export const SELECTED_LAYER_TYPE = 'selected';
export const FOCUSED_LAYER_TYPE = 'focused';
export const CUSTOM_LAYER_TYPE = 'custom';

export const conditionFieldWithCropZones = [
  'all',
  ['==', ['get', '$landType'], 'field'],
  ['>', ['length', ['get', 'cropzones']], 0]
];
export const conditionLayerSelected = [
  '==',
  ['get', '$layer'],
  SELECTED_LAYER_TYPE
];
export const conditionLayerCustom = [
  '==',
  ['get', '$layer'],
  CUSTOM_LAYER_TYPE
];

export const symbolLayoutTemplate = {
  'text-anchor': 'center',
  'text-justify': 'center',
  'text-variable-anchor': ['center'],
  'text-radial-offset': 0,
  'text-size': 10,
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-allow-overlap': true,
  'symbol-sort-key': ['get', 'sortKey']
};

export const customSymbolLayout = {
  ...symbolLayoutTemplate,
  'text-field': [
    'case',
    conditionFieldWithCropZones,
    '',
    ['get', 'customLabel']
  ],
  'symbol-sort-key': ['get', 'symbolSortKey']
};

export const legendSymbolLayout = {
  ...symbolLayoutTemplate,
  'text-field': ['get', 'labelLegendKey'],
  'symbol-sort-key': ['get', 'symbolSortKey']
};

export const customSymbolPaint = {
  'text-color': 'white',
  'text-halo-color': 'black',
  'text-halo-width': 1
};

export const sortMapLayers = mapRef => {
  let layers = mapRef.current?.getStyle()?.layers;
  if (!layers?.length) return;

  layers = layers.filter(layer => layer.id.startsWith('geojson-'));
  layers.forEach(layer => {
    mapRef.current.removeLayer(layer.id);
  });

  const dynamicLayers = [];
  const geojsonLayers = [];
  const geojsonSymbolLayers = [];

  layers.forEach(layer => {
    if (layer.id.startsWith('geojson-dynamic-layer')) {
      dynamicLayers.push(layer);
    } else if (/geojson-\d+-symbol.*/.test(layer.id)) {
      geojsonSymbolLayers.push(layer);
    } else {
      geojsonLayers.push(layer);
    }
  });

  geojsonSymbolLayers.sort((a, b) => {
    const getNumber = id => {
      const num = parseInt(id.split('-')[1], 10);
      if (isNaN(num)) return Infinity;
      return num;
    };
    return getNumber(b.id) - getNumber(a.id);
  });

  const sortedLayers = [
    ...dynamicLayers,
    ...geojsonLayers,
    ...geojsonSymbolLayers
  ];

  sortedLayers.forEach(layer => {
    mapRef.current.addLayer(layer);
  });
};

export const isFeatureSelected = (fieldsToMassiveAssign, feature) =>
  fieldsToMassiveAssign.find(
    field =>
      field.id === feature.properties.id ||
      field.cropzones
        .map(cropzone => cropzone.id)
        .find(id => id === feature.properties.id)
  );

export const hasCustomLabel = feature =>
  feature.properties.$landType === 'cropzone' ||
  (feature.properties.$landType === 'field' &&
    !feature.properties.cropzones.length);

export const buildCustomLabel = (
  properties,
  mapLabelConfig,
  fields,
  nameOnly
) => {
  const customLabel = [];
  if (nameOnly) {
    return properties.name;
  }

  let parentField = properties;
  const isCropZone = properties.$landType === 'cropzone';
  if (isCropZone && properties.$parentFieldId) {
    parentField = fields.find(field => field.id === properties.$parentFieldId);
  }

  if (mapLabelConfig.farmNames) {
    customLabel.push(parentField?.farmName);
  }
  if (mapLabelConfig.fieldNames) {
    customLabel.push(parentField?.name);
  }
  if (mapLabelConfig.cropZoneNames && isCropZone) {
    customLabel.push(properties.name);
  }
  if (mapLabelConfig.assignedCropType && isCropZone && properties.crop) {
    customLabel.push(properties.crop.name);
  }
  if (
    mapLabelConfig.area &&
    (properties.reportedArea || properties.boundaryArea)
  ) {
    customLabel.push(
      formatNumber(properties.reportedArea || properties.boundaryArea, 'ac')
    );
  }
  return customLabel.join('\n');
};
