import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { Input, Select, Spinner, Toast, Button } from '@agconnections/grow-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ToggleButton from 'components/ToggleButton';
import { FieldArray, useFormikContext } from 'formik';
import infoIcon from 'assets/info.svg';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import PortalModal from 'components/Modals/PortalModal';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FormattedNumber from 'components/FormattedNumber';
import { calculateProductValues } from 'helpers/unitConversionHelpers'; // include for unit conversions
import useProduct from 'hooks/useProduct';
import useInventoryApi from 'hooks/useInventoryApi';
import { getApplicationType } from 'screens/Tasks/helpers/dataHelpers';

import {
  getCorrectUnitName,
  getCorrectUnitAbbreviation
} from 'helpers/unitsAbbreviation';
import { validateValue } from 'components/ProductSideBar/helpers/productHelper';
import Decimal from 'decimal.js';
import { Maths } from 'helpers/maths';
import TargetPestField from './components/TargetPestField';

const initialProductValues = values => ({
  totalProductValue: values.totalProductValue || 0,
  ratePerAreaValue: values.ratePerAreaValue || 0,
  ratePerAreaUnit: values.ratePerAreaUnit || values.stdunit || '',
  ratePerTankValue: values.ratePerTankValue || 0,
  ratePerTankUnit: values.ratePerTankUnit || values.stdunit || '',
  totalProductUnit: values.totalProductUnit || values.stdpackageunit || '',
  areaUnit: 'acre',
  appliedAreaValue: values.totalAreaValue || 0,
  coveragePercent: 100,
  appliedAreaUnit: 'acre',
  averagePriceUnitAtTimeOfCreation: '',
  averagePriceAtTimeOfCreation: 0,
  totalCostAtTimeOfCreation: 0,
  specificCostPerUnit: 0,
  specificCostUnit: values.specificCostUnit || values.stdpackageunit,
  applicationMethod: values.applicationMethod || '',
  targetPest: values.targetPest,
  targetPestId: values.targetPestId,
  density: values?.density || values?.productDensity || values?.stdfactor || 0,
  productDensity:
    values?.density || values?.productDensity || values?.stdfactor || 0,

  shareOwnerInformation: {
    percentGrowerResponsibility: 100,
    totalFromGrowerInventoryQuantity: 100,
    totalFromGrowerInventoryUnit: ''
  },
  stdfactor: values.stdfactor || 1,
  stdpackageunit: values.stdpackageunit || '',
  stdunit: values.stdunit || values.stdUnit || '',
  custom: values.custom
});

const applicationMethods = [
  { key: 'None', value: 'None' },
  { key: 'Aerial', value: 'Aerial' },
  { key: 'Air Blast', value: 'Air Blast' },
  { key: 'Chemigation', value: 'Chemigation' },
  { key: 'Fertigation', value: 'Fertigation' },
  { key: 'Fumigation', value: 'Fumigation' },
  { key: 'Ground', value: 'Ground' },
  { key: 'Ground - Banded', value: 'Ground - Banded' },
  { key: 'Ground - Broadcast', value: 'Ground - Broadcast' },
  { key: 'Ground - Hooded', value: 'Ground - Hooded' },
  { key: 'Ground - In Furrow', value: 'Ground - In Furrow' },
  { key: 'Ground - Incorporated', value: 'Ground - Incorporated' },
  { key: 'Ground - Seed Treatment', value: 'Ground - Seed Treatment' },
  { key: 'Ground - Spot', value: 'Ground - Spot' },
  { key: 'Ground - Variable Rate', value: 'Ground - Variable Rate' },
  { key: 'Ground - Wipe', value: 'Ground - Wipe' },
  { key: 'Ground Application', value: 'Ground Application' },
  { key: 'Planting', value: 'Planting' },
  { key: 'Re-Planting', value: 'Re-Planting' },
  { key: 'Sidedress / Topdress', value: 'Sidedress' },
  { key: 'Storage', value: 'Storage' },
  { key: 'Topdress', value: 'Topdress' },
  { key: 'Tree Injection', value: 'Tree Injection' },
  { key: 'Water Run', value: 'Water Run' }
];

const AddProductTaskModal = ({
  open,
  onClose,
  product,
  isEdit,
  prodIndex,
  memberRole,
  memberFinancialAccess,
  selectedApplicationOption
}) => {
  const amplitude = useContext(AmplitudeContext);
  const flags = useFlags();
  const orgId = Context?._currentValue[0]?.organization?.id;
  const isProductPriceFeature =
    flags.fePriceOverride &&
    (flags.beProductPriceListEnabled ||
      flags.beProductPriceListEnableByOrgId?.includes(orgId));
  const inventoryAPI = useInventoryApi('inventory/product');
  const [error, setError] = useState(null);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [appliedAreaChanged, setAppliedAreaChanged] = useState(false);
  const [appliedPercentageChange, setAppliedPercentageChange] = useState(false);
  const [productToAdd, setProductToAdd] = useState(initialProductValues({}));

  const [closedModal, setClosedModal] = useState(true);
  const [stdPackageUnit, setStdPackageUnit] = useState([]);

  const [toggleButtonEnabled, setToggleButtonEnabled] = useState(false);

  const { getMasterListProductUnits, getProductUnits } = useProduct();
  const ref = useRef(null);
  const [productListPrice, setProductListPrice] = useState(0);
  const [
    unitPriceLoadedFromListPrice,
    setUnitPriceLoadedFromListPrice
  ] = useState(false);

  const isResetButtonDisabled =
    !productListPrice ||
    productListPrice === +productToAdd?.averagePriceAtTimeOfCreation;

  const canSaveNewListPrice =
    productListPrice !== +productToAdd?.averagePriceAtTimeOfCreation;

  const toggleButtonExtraStyle = !canSaveNewListPrice
    ? { opacity: 0.5, pointerEvents: 'none' }
    : { opacity: 1, pointerEvents: 'inherit' };

  const gridWidth = isProductPriceFeature ? ' grid-cols-4 ' : ' grid-cols-3 ';
  const hideIfProductPriceDisabled = isProductPriceFeature ? ' ' : ' hidden ';
  const wideIfProductPriceEnabled = isProductPriceFeature
    ? ' col-span-2 '
    : ' ';
  useLayoutEffect(() => {
    if (ref.current) {
      if (isProductPriceFeature) {
        ref.current.closest(`[role=dialog]`).style.maxWidth = '672px';
      }
    }
  });

  useEffect(() => {
    if (closedModal || !productToAdd) {
      setProductToAdd(initialProductValues({}));
      setStdPackageUnit([]);
      setClosedModal(false);
    }
  }, [productToAdd, closedModal]);

  useEffect(() => {
    let newProduct = initialProductValues({});
    if (product) {
      newProduct = initialProductValues({
        ...product,
        applicationMethod: '',
        totalAreaValue: values?.totalAreaValue,
        density: values?.density
      });
    }

    setProductToAdd(newProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, values?.density]);

  const modalType = getApplicationType(selectedApplicationOption);

  const handleClose = () => {
    setClosedModal(true);
    onClose();
  };

  const calcPercentAcres = (totalAcres, changeAcres) => {
    let newAcres = 0;
    if (Number.parseFloat(totalAcres) > 0) {
      newAcres = Maths.parseFloatRound(
        (Number.parseFloat(changeAcres) / Number.parseFloat(totalAcres)) * 100,
        2,
        Decimal.ROUND_HALF_UP
      );
    }

    return newAcres;
  };

  const calcTotalAcres = (totalAcres, percentAcres) => {
    let newAcres = 0;
    if (Number.parseFloat(percentAcres) > 0) {
      newAcres = Maths.parseFloatRound(
        totalAcres * (percentAcres / 100),
        2,
        Decimal.ROUND_HALF_UP
      );
    }

    return newAcres;
  };

  const calcProductValues = (
    valueChanged,
    targetValue,
    changedUnits,
    changedAppArea,
    newPrice
  ) => {
    const newProductToAdd = calculateProductValues(
      valueChanged,
      targetValue,
      changedUnits,
      changedAppArea,
      newPrice,
      productToAdd,
      values
    );
    setProductToAdd(newProductToAdd);
  };

  const productUnitChange = unit => {
    const units = {
      rateUnit:
        modalType.key === 'ByRatePerArea'
          ? unit
          : productToAdd?.ratePerAreaUnit,
      tankUnit:
        modalType.key === 'ByRatePerTank'
          ? unit
          : productToAdd?.ratePerTankUnit,
      totalProdUnit:
        modalType.key === 'ByTotalProduct'
          ? unit
          : productToAdd?.totalProductUnit
    };
    calcProductValues(modalType.key, productToAdd?.[modalType.value], units);
  };

  const calcValuesAcresChange = (validatedValue, propertyChanged) => {
    const areaChanged = propertyChanged === 'area';

    const newAppliedPct = areaChanged
      ? calcPercentAcres(values?.totalAreaValue, validatedValue)
      : validatedValue;

    const newAppliedArea = !areaChanged
      ? calcTotalAcres(values?.totalAreaValue, validatedValue)
      : validatedValue;

    const acresChangedValues = {
      changedPct: newAppliedPct,
      changedArea: newAppliedArea
    };

    calcProductValues(
      modalType.key,
      productToAdd?.[modalType.value],
      {},
      acresChangedValues
    );
  };

  const priceBagChange = newPrice => {
    calcProductValues(
      modalType.key,
      productToAdd?.[modalType.value],
      {},
      {},
      newPrice
    );
  };

  const productAppliedAreaChange = e => {
    if (!appliedPercentageChange) {
      const validatedValue = validateValue(
        e.target.value,
        values?.totalAreaValue
      );
      calcValuesAcresChange(validatedValue, 'area');
    }
  };

  const productAppliedAreaPercentChange = e => {
    if (e.target.value) {
      if (!appliedAreaChanged) {
        const validatedValue = validateValue(e.target.value, 100);
        calcValuesAcresChange(validatedValue, 'pct');
      }
    }
  };
  const setProductValues = productValues => {
    const newProduct = initialProductValues(productValues);
    newProduct.density =
      productValues.density ||
      productValues.productDensity ||
      productValues.stdfactor;
    newProduct.totalProductValue = productValues.totalProductValue;
    newProduct.ratePerAreaValue = productValues.ratePerAreaValue;
    newProduct.ratePerTankValue = productValues.ratePerTankValue;
    newProduct.areaUnit = productValues.areaUnit;
    newProduct.appliedAreaValue = productValues.appliedAreaValue;
    newProduct.coveragePercent = productValues.coveragePercent;
    newProduct.appliedAreaUnit = productValues.appliedAreaUnit;
    newProduct.averagePriceUnitAtTimeOfCreation =
      productValues.averagePriceUnitAtTimeOfCreation;
    newProduct.averagePriceAtTimeOfCreation =
      productValues.averagePriceAtTimeOfCreation;
    newProduct.totalCostAtTimeOfCreation =
      productValues.totalCostAtTimeOfCreation;
    newProduct.specificCostPerUnit = productValues.specificCostPerUnit;
    newProduct.specificCostUnit = productValues.specificCostUnit;
    newProduct.applicationMethod = productValues.applicationMethod;
    newProduct.shareOwnerInformation = productValues.shareOwnerInformation;

    return newProduct;
  };

  useEffect(() => {
    const mappedProduct = isEdit
      ? setProductValues({
          ...product,
          applicationMethod: '',
          totalAreaValue: values?.totalAreaValue
        })
      : initialProductValues({
          ...product,
          applicationMethod: '',
          totalAreaValue: values?.totalAreaValue
        });
    if (isEdit) {
      setFieldValue('productUnit', product?.[modalType.unit]);
      setFieldValue('applicationMethod', product?.applicationMethod);
      if (product?.saveAsNewListPriceDate) {
        setToggleButtonEnabled(true);
      }
    } else {
      setFieldValue('productUnit', product?.stdunit);
      setFieldValue('applicationMethod', '');
    }
    setProductToAdd(mappedProduct);
    setAppliedAreaChanged(false);
    setAppliedPercentageChange(false);
  }, [
    isEdit,
    product,
    values?.totalAreaValue,
    modalType.unit,
    setFieldValue,
    values?.density
  ]);

  useEffect(() => {
    if (open && product) {
      if (!product.custom) {
        getMasterListProductUnits(product.id).then(setStdPackageUnit);
      } else {
        getProductUnits(product.id).then(setStdPackageUnit);
      }
      if (isProductPriceFeature) {
        inventoryAPI
          .fetch(product.id)
          .promise.then(productDataResponse => {
            setProductListPrice(productDataResponse.data.averagePricePerUnit);
          })
          .catch(() => {
            setProductListPrice(0);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isEdit, product]);

  useEffect(() => {
    if (!open) return;
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Tasks.addProductSuccess
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && product && productListPrice && !unitPriceLoadedFromListPrice) {
      if (!isEdit) {
        setUnitPriceLoadedFromListPrice(true);
        setProductToAdd({
          ...productToAdd,
          averagePriceAtTimeOfCreation: productListPrice
        });
      }
    }
  }, [
    open,
    isEdit,
    product,
    productListPrice,
    productToAdd,
    unitPriceLoadedFromListPrice
  ]);

  const calcGrowerCost = () => {
    return (
      productToAdd?.totalCostAtTimeOfCreation *
      (productToAdd?.shareOwnerInformation?.percentGrowerResponsibility / 100)
    );
  };

  const getPriceUnitLabel = () => {
    if (isProductPriceFeature && !isEdit) {
      return 'Price/Unit';
    }
    let label = 'Price/';
    label += productToAdd?.stdpackageunit;

    return label;
  };

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;

    return false;
  };

  const displayTotalCostValue = () => {
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'none' &&
      isEdit
    ) {
      return true;
    }
    if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
      return false;
    }
    return false;
  };

  return (
    <>
      <FieldArray name="products">
        {({ push }) => (
          <PortalModal
            open={open}
            close={onClose}
            onCancel={() => {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Tasks.cancelProduct
              );
              handleClose();
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Tasks.cancelProductSuccess
              );
            }}
            onConfirm={() => {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Tasks.saveProduct
              );
              setFieldTouched('save product');
              if (!isEdit) {
                push({
                  ...productToAdd,
                  saveAsNewListPriceDate: toggleButtonEnabled
                    ? new Date().getTime()
                    : 0,
                  productName: product.name,
                  manufacturerName: product.manufacturer,
                  productId: product.id
                });
              } else {
                values.products[prodIndex] = {
                  ...productToAdd,
                  saveAsNewListPriceDate: toggleButtonEnabled
                    ? new Date().getTime()
                    : 0,
                  productName: product.name,
                  manufacturerName: product.manufacturer,
                  productId: product.id
                };
              }

              handleClose();
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Tasks.saveProductSuccess
              );
            }}
          >
            <div ref={ref} />
            {error && (
              <Toast icon="error" onClose={() => setError('')}>
                {error}
              </Toast>
            )}
            {!product ? (
              <Spinner />
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <div className="font-bold text-lg" data-testid="product-name">
                    {product.name}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="text-sm text-neutral-300"
                    data-testid="manufacturer"
                  >
                    {product.manufacturer}
                  </div>
                </div>
                <div className="flex mb-6 mt-6">
                  {(selectedApplicationOption === 'ByTotalProduct' ||
                    selectedApplicationOption === 'ByRatePerTank') && (
                    <div className="mr-6">
                      <span className="text-sm text-neutral-300">
                        Rate/Area
                      </span>
                      <div>
                        <div className="text-xl">
                          {productToAdd?.ratePerAreaValue}{' '}
                          {getCorrectUnitAbbreviation(
                            productToAdd?.ratePerAreaUnit
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedApplicationOption !== 'ByTotalProduct' && (
                    <div className="mr-6">
                      <span className="text-sm text-neutral-300">
                        Total Product
                      </span>
                      <div className="text-xl" data-testid="total-product">
                        {Number(productToAdd?.totalProductValue).toFixed(2)}{' '}
                        {getCorrectUnitAbbreviation(
                          productToAdd?.totalProductUnit
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mr-6">
                    <span className="text-sm text-neutral-300">
                      Product Cost
                    </span>
                    <div className="text-xl">
                      <FormattedNumber unit="usCurrency" roundedTo={2}>
                        {productToAdd?.totalCostAtTimeOfCreation}
                      </FormattedNumber>
                    </div>
                  </div>
                </div>
                <div className="mt-12">
                  <div className={`grid ${gridWidth} gap-6 mb-12`}>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label={modalType.title}
                        name={modalType.value}
                        value={productToAdd?.[modalType.value]}
                        onChange={e => {
                          calcProductValues(
                            selectedApplicationOption,
                            e.target.value
                          );
                        }}
                        onFocus={e => e.target.select()}
                      />
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        style={{ whiteSpace: 'nowrap' }}
                        control={Select}
                        label="Unit"
                        name="productUnit"
                        items={stdPackageUnit}
                        value={getCorrectUnitName(
                          productToAdd?.[modalType.unit]
                        )}
                        onChange={e => {
                          productUnitChange(JSON.parse(e.target.value).key);
                        }}
                      />
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label="Applied Area"
                        name="productAppliedArea"
                        value={productToAdd?.appliedAreaValue}
                        onChange={productAppliedAreaChange}
                        onFocus={() => setAppliedAreaChanged(true)}
                        onBlur={() => setAppliedAreaChanged(false)}
                        imask={{
                          mask: 'num \\ac',
                          lazy: false,
                          blocks: {
                            num: {
                              mask: Number,
                              radix: '.',
                              mapToRadix: [','],
                              thousandsSeparator: ','
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label="Applied Area %"
                        name="productAppliedAreaPercent"
                        value={productToAdd?.coveragePercent}
                        onChange={productAppliedAreaPercentChange}
                        onFocus={() => setAppliedPercentageChange(true)}
                        onBlur={() => setAppliedPercentageChange(false)}
                        imask={{
                          mask: 'num%',
                          lazy: false,
                          blocks: {
                            num: {
                              mask: Number,
                              radix: '.',
                              mapToRadix: [','],
                              thousandsSeparator: ','
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="my-2 relative">
                      <GrowUIFormField
                        control={Input}
                        label={getPriceUnitLabel()}
                        name="pricePerUnit"
                        disabled={getDisabled()}
                        value={productToAdd?.averagePriceAtTimeOfCreation || 0}
                        onChange={e => {
                          if (e.target.value) {
                            priceBagChange(e.target.value);
                          }
                        }}
                        maskType="usCurrency"
                      />
                      <div
                        data-test-id="unitLabel"
                        className={`${hideIfProductPriceDisabled} absolute gr-text-neutral-600 pointer-events-none`}
                        style={{
                          right: 5,
                          top: 6
                        }}
                      >
                        /
                        {getCorrectUnitAbbreviation(
                          productToAdd?.stdpackageunit
                        )}
                      </div>
                    </div>
                    <div
                      className={`${hideIfProductPriceDisabled} my-2 w-full`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <Button
                        label="Reset"
                        name="reset"
                        type="primary-ghost"
                        disabled={isResetButtonDisabled}
                        onClick={() => {
                          amplitude.sendEventToAmplitude(
                            amplitude.events.epic.ProductList.resetToListPrice,
                            { productId: product.id }
                          );
                          priceBagChange(productListPrice);
                        }}
                      >
                        Reset to List Price
                      </Button>
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label="Total Grower Cost"
                        name="totalGrowerCost"
                        value={
                          displayTotalCostValue()
                            ? ''
                            : calcGrowerCost().toFixed(2)
                        }
                        disabled
                      />
                    </div>
                    <div
                      style={toggleButtonExtraStyle}
                      className={`${hideIfProductPriceDisabled} mb-2 -mt-6 col-span-4`}
                    >
                      <div className="flex flex-row justify-left">
                        <span className="inline-block">
                          {!canSaveNewListPrice ? (
                            <ToggleButton
                              key="disabled"
                              id="disabledSavePriceList"
                              name="disabledSavePriceList"
                            />
                          ) : (
                            <ToggleButton
                              key="enabled"
                              id="savePriceList"
                              color="#0071CD"
                              name="savePriceList"
                              defaultChecked={toggleButtonEnabled}
                              onChange={e =>
                                setToggleButtonEnabled(e.currentTarget.checked)
                              }
                            />
                          )}
                        </span>
                        <label
                          htmlFor={
                            canSaveNewListPrice
                              ? 'savePriceList'
                              : 'disabledSavePriceList'
                          }
                          className="inline-block pl-2 -mt-2px text-sm"
                        >
                          Save as new list price
                        </label>
                      </div>
                      <div
                        className={`flex flex-row justify-left ${
                          toggleButtonEnabled && canSaveNewListPrice
                            ? ''
                            : 'invisible'
                        }`}
                      >
                        <img alt="info" src={infoIcon} className="mr-1 mt-1" />
                        <span className="text-sm mt-1">
                          This will not change any existing records
                        </span>
                      </div>
                    </div>
                    <div className={`my-2 ${wideIfProductPriceEnabled}`}>
                      <GrowUIFormField
                        control={Select}
                        label="Application Method"
                        name="applicationMethod"
                        items={applicationMethods}
                        onChange={e => {
                          setProductToAdd({
                            ...productToAdd,
                            applicationMethod: JSON.parse(e.target.value).key
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2 -mt-3">
                      <TargetPestField
                        pest={product.targetPest}
                        onSelectPest={pest => {
                          setProductToAdd({
                            ...productToAdd,
                            targetPest: pest
                              ? {
                                  latinNames: pest.latinnames,
                                  name: pest.name,
                                  id: pest.nameid
                                }
                              : undefined
                          });
                        }}
                      />
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label="Grower Share"
                        name="growerShare"
                        value={
                          productToAdd?.shareOwnerInformation
                            ?.percentGrowerResponsibility
                        }
                        disabled
                        onChange={e => {
                          setProductToAdd({
                            ...productToAdd,
                            shareOwnerInformation: {
                              ...productToAdd?.shareOwnerInformation,
                              percentGrowerResponsibility: e.target.value
                            }
                          });
                        }}
                        imask={{
                          mask: 'num%',
                          lazy: false,
                          blocks: {
                            num: {
                              mask: Number,
                              radix: '.',
                              mapToRadix: [','],
                              thousandsSeparator: ','
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="my-2">
                      <GrowUIFormField
                        control={Input}
                        label="From Inventory"
                        name="fromInventory"
                        disabled
                        value={
                          productToAdd?.shareOwnerInformation
                            ?.totalFromGrowerInventoryQuantity
                        }
                        onChange={e => {
                          setProductToAdd({
                            ...productToAdd,
                            shareOwnerInformation: {
                              ...productToAdd?.shareOwnerInformation,
                              totalFromGrowerInventoryQuantity: e.target.value
                            }
                          });
                        }}
                        imask={{
                          mask: 'num%',
                          lazy: false,
                          blocks: {
                            num: {
                              mask: Number,
                              radix: '.',
                              mapToRadix: [','],
                              thousandsSeparator: ','
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </PortalModal>
        )}
      </FieldArray>
    </>
  );
};

AddProductTaskModal.defaultProps = {
  prodIndex: 0,
  product: null
};

AddProductTaskModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  prodIndex: PropTypes.number,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  selectedApplicationOption: PropTypes.string.isRequired
};

export default AddProductTaskModal;
