import genResource from './helpers/genResource';
import {
  MOCK_API_URL,
  LOCAL_ROOT,
  CROPWISE_PROXY_URL,
  CROPWISE_PROXY_V2_URL,
  MASTERLIST_CROPS_URL,
  MASTERLIST_PROXY_URL,
  CROPWISE_MASTERDATA,
  AG_CLEAR_APP_URL
} from './apiConstants';

// Org Resources
export const genMock = genResource('mock', LOCAL_ROOT, {
  parentResources: [
    {
      resource: 'organizations',
      idCallback: () => process.env.REACT_APP_DEVTEST_ORGANIZATIONID
    }
  ]
});

// Resources
export const crops = genResource('crops', MASTERLIST_CROPS_URL, {
  cwfContextRegExpExclusions: [/\/crops/]
});
export const products = genResource('products', MASTERLIST_PROXY_URL);
export const product = genResource('product', `${CROPWISE_MASTERDATA}`);
export const productsMasterData = genResource(
  'products',
  `${CROPWISE_MASTERDATA}`
);
export const tags = genResource('flags', `${CROPWISE_MASTERDATA}`);
export const tag = genResource('flag', `${CROPWISE_MASTERDATA}`);
export const costPerAcres = genResource('cost-per-acres', MOCK_API_URL);
export const dashboards = genResource('dashboards', MOCK_API_URL);
export const imagery = genResource('imagery', MOCK_API_URL);
export const organizations = genResource(
  'organizations',
  `${CROPWISE_PROXY_URL}/v1/base`,
  {
    cwfContextRegExpExclusions: [/\/organizations/]
  }
);
export const organization = genResource(
  'organization',
  `${CROPWISE_PROXY_URL}/v1/base`
);

export const LMOrganization = genResource(
  'organization/land-management-enabled',
  `${CROPWISE_PROXY_URL}/v1/base`
);
export const properties = genResource(
  'properties',
  `${CROPWISE_PROXY_URL}/v1/base`
);
export const propertiesV2 = genResource(
  'properties',
  `${CROPWISE_PROXY_URL}/v2/base`
);
export const propertiesV3 = genResource(
  'properties',
  `${CROPWISE_PROXY_URL}/v3/base`
);
export const propertiesV4 = genResource(
  'properties',
  `${CROPWISE_PROXY_URL}/v4/base`
);
export const dashboardWeather = genResource('dashboardWeather', MOCK_API_URL);
export const season = genResource(
  'cropseason',
  `${CROPWISE_PROXY_URL}/v2/base`
);
export const seasons = genResource(
  'cropseasons',
  `${CROPWISE_PROXY_URL}/v2/base`
);
export const recommendation = genResource(
  'recommendation',
  `${CROPWISE_PROXY_URL}/v1`
);
export const recommendations = genResource(
  'recommendations',
  `${CROPWISE_PROXY_URL}/v1`
);
export const recommendationsSearch = genResource(
  'recommendations/search',
  `${CROPWISE_PROXY_URL}/v1`
);
export const documentDetails = genResource(
  'products',
  `${MASTERLIST_PROXY_URL}`
);
export const submission = genResource(
  'integrations/submission',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);
export const simplotMatch = genResource(
  'integrations/simplot/match',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);
export const simplotCropZones = genResource(
  'integrations/simplot/cropzones',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const agrianFields = genResource(
  'integrations/agrian/fields',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const agrianMatch = genResource(
  'integrations/agrian/match',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const integrations = genResource(
  'integrations',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const agrian = genResource(
  'integrations/agrian',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const calAg = genResource(
  'integrations/calag',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const incomingInvoice = genResource(
  'integrations/incoming-invoice',
  `${CROPWISE_PROXY_V2_URL}`,
  {
    cwfContextRegExpExclusions: [/organizations/g],
    customCwfContext: true
  }
);

export const documentData = genResource('documents', `${MASTERLIST_PROXY_URL}`);
export const farm = genResource('farm', `${CROPWISE_PROXY_URL}/v1/base`);
export const farms = genResource('farms', `${CROPWISE_PROXY_URL}/v1/base`);
export const field = genResource('field', `${CROPWISE_PROXY_URL}/v1/base`);
export const fieldV2 = genResource('field', `${CROPWISE_PROXY_URL}/v2/base`);
export const cropZone = genResource(
  'cropzone',
  `${CROPWISE_PROXY_URL}/v1/base`
);
export const cropZoneV2 = genResource(
  'cropzone',
  `${CROPWISE_PROXY_URL}/v2/base`
);
export const cropZoneV3 = genResource(
  'cropzone',
  `${CROPWISE_PROXY_URL}/v3/base`
);
export const tasks = genResource('tasks', `${CROPWISE_PROXY_URL}/v1/base`);
export const task = genResource('task', `${CROPWISE_PROXY_URL}/v1/base`);
export const taskProducts = genResource(
  'products-per-season',
  `${CROPWISE_PROXY_URL}/v1/base/tasks`
);

export const plans = genResource('plans', `${CROPWISE_PROXY_URL}/v1`);
export const plan = genResource('plan', `${CROPWISE_PROXY_URL}/v1`);

export const connectAPI = genResource(
  'johnDeere',
  `${CROPWISE_PROXY_URL}/v1/integration`
);

export const company = genResource('company', `${CROPWISE_PROXY_URL}/v1/base`);
export const invoices = genResource('invoices', `${CROPWISE_PROXY_URL}/v1`);
export const invoice = genResource('invoice', `${CROPWISE_PROXY_URL}/v1`);
export const companies = genResource(
  'companies',
  `${CROPWISE_PROXY_URL}/v1/base`
);
export const equipment = genResource(
  'equipment',
  `${CROPWISE_PROXY_URL}/v1/base`
);
export const weather = genResource(
  'weather',
  `${AG_CLEAR_APP_URL}/${process.env.REACT_APP_AG_CLEAR_APP_HOURLY_HISTORICAL_PATH}`
);
export const mcCain = genResource(
  'mcCain',
  `${CROPWISE_PROXY_URL}/v1/integration`
);

export const inventory = genResource(
  'inventory',
  `${CROPWISE_PROXY_URL}/v1/inventory`
);
export const inventoryProducts = genResource(
  'products',
  `${CROPWISE_PROXY_URL}/v1/inventory`
);
export const inventoryProduct = genResource(
  'product',
  `${CROPWISE_PROXY_URL}/v1/inventory`
);

export const inventoryProductPrices = genResource(
  'products-prices',
  `${CROPWISE_PROXY_URL}/v1/inventory`
);

export const reports = genResource('reports', `${CROPWISE_PROXY_URL}/v1/base`);

export const yields = genResource('yields', `${CROPWISE_PROXY_URL}/v2/base`);

export const embeddedReport = genResource(
  'reportInGroup',
  `${CROPWISE_PROXY_URL}/v1/integration/powerBi`
);

export const reiphisData = genResource('reiphis', `${MASTERLIST_PROXY_URL}`);
export const newProxyReiphisData = genResource(
  'reiphi',
  `${CROPWISE_PROXY_URL}/v1/base`
);

export const yieldPath = `${CROPWISE_PROXY_URL}/v2/yield`;

export const yieldList = genResource('list', yieldPath, {
  cwfContextRegExpExclusions: [/organizations/g, /yield/g],
  customCwfContext: true
});

export const yieldDashboard = genResource(
  'dashboard',
  `${CROPWISE_PROXY_URL}/v2/yield`,
  {
    cwfContextRegExpExclusions: [/organizations/g, /yield/g],
    customCwfContext: true
  }
);

export const yieldApi = genResource('yield', `${CROPWISE_PROXY_URL}/v2`, {
  cwfContextRegExpExclusions: [/organizations/g, /yield/g],
  customCwfContext: true
});

// genAxiosInstance
export const genProxyResource = (
  resourceName,
  { parentResources, action, urlVersion = '' } = {}
) =>
  genResource(resourceName, `${CROPWISE_PROXY_URL}${urlVersion}`, {
    parentResources,
    action
  });
export { genResource };

export const hourlyHistoricalPath = `${AG_CLEAR_APP_URL}/${process.env.REACT_APP_AG_CLEAR_APP_HOURLY_HISTORICAL_PATH}`;
export const hourlyForecastPath = `${AG_CLEAR_APP_URL}/${process.env.REACT_APP_AG_CLEAR_APP_HOURLY_FORECAST_PATH}`;

export const createChildApi = (api, endpoint) =>
  api.createChildApi({ action: endpoint });

export const getUnmappedCropZonesOperationsNumber = genResource(
  'unmappedCropZoneCount',
  `${CROPWISE_PROXY_URL}/v1/integration/johnDeere`,
  {}
);
export const getUnmappedFarms = genResource(
  'properties',
  `${CROPWISE_PROXY_URL}/v1/integration/johnDeere/`,
  {}
);

export const cropZoneMatcher = genResource(
  'matchCropzones/bulk',
  `${CROPWISE_PROXY_URL}/v1/integration/johnDeere`,
  {}
);

export const mappedOperations = genResource(
  'operations',
  `${CROPWISE_PROXY_URL}/v1/integration/johnDeere`,
  {}
);

export const johnDeereMissingPackageWeight = genResource(
  'missing-pw-summary',
  `${CROPWISE_PROXY_URL}/v1/integration/johnDeere/operations`,
  {}
);

export const bulkCropZoneCreation = genResource(
  'cropzones',
  `${CROPWISE_PROXY_URL}/v1/base`,
  {}
);
