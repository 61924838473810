import React, { useCallback, useContext } from 'react';
import { Context } from 'components/Store';
import { GLOBAL_SET_ERROR } from 'reducers/reducer';
import EmptyStateImage from 'assets/Org-api-failure-empty-state.svg';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import HelpButton from 'components/HelpButton';
import ProfileButton from 'components/ProfileButton';
import Logo from 'assets/cropwise-financials-logo.svg';

const OrgApiFailure = () => {
  const [, dispatch] = useContext(Context);

  const handleClearError = useCallback(
    () => dispatch({ type: GLOBAL_SET_ERROR, payload: null }),
    [dispatch]
  );
  const handleClear = () => {
    handleClearError();
    window.location = '/app';
  };

  return (
    <div className="flex flex-col h-screen" data-testid="org-api-failure">
      <div
        className="flex px-4 bg-white border-b border-neutral-20"
        data-testid="header"
      >
        <div className="flex justify-between w-full py-3px ml-0">
          <div className="flex items-center">
            <img
              src={Logo}
              alt="Cropwise Financials Logo"
              className="h-16"
              data-testid="logo"
            />
          </div>
          <div className="flex items-center ml-4 md:ml-6">
            <HelpButton data-testid="help-button" />
            <div className="ml-3">
              <ProfileButton data-testid="profile-button" />
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex-grow flex h-full justify-center items-center bg-neutral-10"
        data-testid="main-content"
      >
        <div
          className="flex flex-col justify-center items-center bg-white rounded-lg shadow-sm-card h-512px"
          style={{
            width: '1040px',
            top: '25%'
          }}
          data-testid="error-container"
        >
          <div className="flex flex-col max-w-lg">
            <img
              src={EmptyStateImage}
              alt="unable to load organization"
              data-testid="org-load-error-image"
            />

            <div className="flex flex-col">
              <h1
                className="text-xl font-semibold text-center my-4"
                data-testid="error-heading"
              >
                Unable to load your organization
              </h1>

              <p
                className="text-base text-center mb-8"
                data-testid="error-paragraph"
              >
                There was an error fetching your organization data. Try
                refreshing the page. If the problem persists, contact the
                support team.
              </p>
            </div>

            <div
              className="flex flex-row space-x-4 justify-center"
              data-testid="button-group"
            >
              <Button
                type={ButtonType.outline}
                onClick={() => {
                  window.open(
                    'https://www.syngentadigital.com/us-en#support',
                    '_blank',
                    'noopener'
                  );
                }}
                data-testid="contact-support-button"
              >
                Contact Support
              </Button>

              <Button
                type={ButtonType.primary}
                data-testid="refresh-page-button"
                onClick={handleClear}
              >
                Refresh Page
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgApiFailure;
